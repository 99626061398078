import React, { useCallback, useEffect, useState } from "react";
import { Box } from "../../components/Blocks";
import { makeAPICall } from "../../API/apiCall";
import apiRoutes from "../../API/routes";


const Video = ({id}) => {
    const [movieDetailData, setMovieDetailData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await makeAPICall({
                    route: `${apiRoutes.movies.video}/${id}`,
                    method: "GET",
                });

                if (response) {
                    console.log(response);
                    setMovieDetailData(response);
                }
            } catch (error) {
                console.log('vvideo', error);
            }
        };

        fetchData();
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const getHeight = useCallback(() => {
        const aspectRatio = 256 / 455;
        const height = width * aspectRatio;
      
        return height;
    }, [width]);

    return (
        movieDetailData?
        <Box
            height={`${getHeight()}px`}
            dangerouslySetInnerHTML={{ 
                __html: `
                <iframe 
                    backgroundColor="red"
                    src="${movieDetailData}" 
                    frameborder="0" 
                    width="${width}"
                    allow="autoplay; fullscreen; clipboard-write" 
                    title="Yesuf Abeba"
                ></iframe>`
            }}
        />:
        <></>
    );
}

export default Video;