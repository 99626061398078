import { useSelector } from "react-redux";
import { theme } from "../../../styles/theme";
import { Button, Flex } from "../../components/Blocks";
import Typography from "../../components/Blocks/Typography";
import Layout from "../../components/Layout";
import styled from "styled-components";
import { useState } from "react";
import TextInput from "../../components/Blocks/TextInput";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Country } from "country-state-city";
import { CloseIcon } from "../../../assets/assets";
import apiRoutes from "../../API/routes";
import { getUserFromLocalStorage } from "../../../utils/functions";
import { Box, Modal } from "@mui/material";

const API_ROUTE = process.env.REACT_APP_BASE_URL;

const LoginButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${theme.fontSizes[5]};
`;

const StyledFlex = styled(Flex)`
  border: 0.5px solid ${theme.colors.white[0]};
  border-radius: 8px;
`;

const MainBtns = styled(Flex)`
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;

const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const UserDetail = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [invalidForm, setIsInvalidForm] = useState(false);
  const user = useSelector((state) => state.user.user);
  const access = getUserFromLocalStorage();
  const token = access?.access;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditProfile = () => {
    setIsModalOpen(true);
  };

  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createUser = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("file");
    const file = fileInput.files[0];

    let userData = {
      username: formData.username,
      first_name: formData.first_name,
      last_name: formData.last_name,
    };

    let userForm = new FormData();
    userForm.append("username", formData.username);
    userForm.append("first_name", formData.first_name);
    userForm.append("last_name", formData.last_name);
    userForm.append("profile_picture", file);

    const url = `${API_ROUTE}/${apiRoutes.user.update}/${user?.id}/`;
    console.log(url, "url");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `JWT ${token}`,
      },
      body: userForm,
    });
    if (response.ok) {
      const updatedUserData = await response.json();
      // Handle the updated user data
      console.log(updatedUserData);
    } else {
      // Handle the error
      console.log("Failed to update user data");
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <Flex
        flexDirection={["column"]}
        margin={["auto"]}
        height={["fit-content"]}
        py={[5]}
        width={["100%", "60%", "50%", "30%"]}
      >
        <Flex
          my={5}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
        >
          <Flex
            backgroundColor={theme.colors.black[0]}
            borderRadius={"50%"}
            height={"280px"}
            width={"280px"}
            mx={[2]}
          >
            <img
              src={user?.profile_picture}
              height="100%"
              width="100%"
              style={{ borderRadius: "50%" }}
            />
          </Flex>
        </Flex>

        <MainBtns px={[3]} py={[3]}>
          <Button onClick={handleEditProfile} width={"100%"}>
            Edit Profile
          </Button>

          <Modal
            onClose={() => setIsModalOpen(false)}
            open={isModalOpen}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                borderRadius: "6px",
                pt: 2,
                px: 4,
                pb: 3,
                width: "60%", // Default width for larger screens
                // Media query for screens below 960px
                "@media (max-width: 960px)": {
                  width: "70%",
                },
                // Media query for screens below 600px
                "@media (max-width: 700px)": {
                  width: "95%",
                },
              }}
            >
              <CloseBtn onClick={handleClose}>
                <CloseIcon height={"24px"} width={"24px"} />
              </CloseBtn>
              <form
                style={{ padding: "4rem 2rem 2rem 2rem" }}
                onSubmit={createUser}
              >
                <TextInput
                  placeholder="Username"
                  p={2}
                  name="username"
                  my={["10px"]}
                  width={["100%"]}
                  value={formData.username}
                  onChange={handleInputChange}
                />
                <TextInput
                  placeholder="First Name"
                  p={2}
                  name="first_name"
                  my={["10px"]}
                  width={["100%"]}
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                <TextInput
                  placeholder="Last Name"
                  p={2}
                  name="last_name"
                  my={["10px"]}
                  width={["100%"]}
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
                <input
                  type="file"
                  id="file"
                  style={{
                    height: "48px",
                    backgroundColor: "#f4f4f4",
                    width: "100%",
                    padding: "14px",
                    margin: "8px 0",
                    borderRadius: "4px",
                  }}
                />

                <LoginButton type="submit" width={"100%"}>
                  Update
                </LoginButton>
              </form>
            </Box>
          </Modal>
        </MainBtns>

        <Flex
          p={[24]}
          // height={["64px"]}
          justifyContent={["space-between"]}
          color={theme.colors.white[0]}
          borderBottom={["0.1px solid #3a6073"]}
        >
          <Typography>Username</Typography>
          <Typography>{user?.username}</Typography>
        </Flex>
        <Flex
          p={[24]}
          // height={["64px"]}
          justifyContent={["space-between"]}
          color={theme.colors.white[0]}
          borderBottom={["0.1px solid #3a6073"]}
        >
          <Typography>First Name</Typography>
          <Typography>{user?.first_name}</Typography>
        </Flex>
        <Flex
          p={[24]}
          // height={["64px"]}
          justifyContent={["space-between"]}
          color={theme.colors.white[0]}
          borderBottom={["0.1px solid #3a6073"]}
        >
          <Typography>Email</Typography>
          <Typography>{user?.email}</Typography>
        </Flex>
        <Flex
          p={[24]}
          // height={["64px"]}
          justifyContent={["space-between"]}
          color={theme.colors.white[0]}
          borderBottom={["0.1px solid #3a6073"]}
        >
          <Typography>Phone Number</Typography>
          <Typography>{user?.phone_number}</Typography>
        </Flex>
        <Flex
          p={[24]}
          // height={["64px"]}
          justifyContent={["space-between"]}
          color={theme.colors.white[0]}
          borderBottom={["0.1px solid #3a6073"]}
        >
          <Typography>Country</Typography>
          <Typography>{user?.country}</Typography>
        </Flex>
        <Flex
          p={[24]}
          // height={["64px"]}
          justifyContent={["space-between"]}
          color={theme.colors.white[0]}
        >
          <Typography>Birth Date</Typography>
          <Typography>{user?.date_of_birth}</Typography>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default UserDetail;
