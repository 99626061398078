import styled from "styled-components";
import { Flex } from "../../components/Blocks/Flex";
import Typography from "../../components/Blocks/Typography";
import { theme } from "../../../styles/theme";
import { useNavigate } from "react-router-dom";

const MainContainer = styled(Flex)`
  background: linear-gradient(to right, #16222a, #3a6073);
`;

const LoginButton = styled.button`
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 4rem;
  font-size: ${theme.fontSizes[5]};
`;

const PrivacyTerms = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainContainer
      height={["100%"]}
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      px={"5%"}
      py={"5%"}
      color={theme.colors.white[0]}
    >
      <LoginButton onClick={handleBack}>Go Back</LoginButton>

      <Typography fontSize={["32px"]} fontWeight={["800"]}>
        Privacy Statement
      </Typography>
      <Typography>
        This Privacy Statement explains our practices, including your choices,
        regarding the collection, use, and disclosure of certain information,
        including your personal information in connection with the Arkwood’s
        service.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Contacting Us
      </Typography>
      <Typography>
        If you have general questions about your account or how to contact
        customer service for assistance, please visit www.ethioarkwood.com. The
        data controller of your personal information is Ethio Arkwood
        Entertainment S.C. Please note that if you contact us to assist you, for
        your safety and ours we may need to authenticate your identity before
        fulfilling your request.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Collection of Information
      </Typography>
      <Typography>
        We receive and store information about you such as:
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Information you provide to us
      </Typography>
      <Typography>
        We collect information you provide to us which includes
      </Typography>
      <ul>
        <li>
          your name,Your Phone Number, Your email address, payment method(s),
          and other identifiers you might use. We collect this information in a
          number of ways, including when you enter it while using our service,
          interact with our customer service, or participate in surveys or
          marketing promotions
        </li>
        <li>
          information when you choose to provide ratings, taste preferences,
          account settings (including preferences set in the "Account" section
          of our website), or otherwise provide information to us through our
          service or elsewhere.
        </li>
      </ul>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Information we collect automatically
      </Typography>
      <Typography>
        We collect information about you and your use of our service, your
        interactions with us and our advertising, as well as information
        regarding your network, network devices, and your computer or other
        capable devices you might use to access our service (such as gaming
        systems, smart TVs, mobile devices, set top boxes, and other streaming
        media devices). This information includes
      </Typography>
      <ul>
        <li>
          your activity on the Arkwood service, such as title selections, shows
          you have watched and search queries.
        </li>
        <li>
          your interactions with our SMS, emails and texts, and with our
          messages through push and online messaging channels
        </li>
        <li>
          details of your interactions with our customer service, such as the
          date, time and reason for contacting us, transcripts of any chat
          conversations, and if you call us, your phone number and call
          recordings
        </li>
        <li>
          device IDs or other unique identifiers, including for your network
          devices, and devices that are capable on your Wi-Fi network
        </li>
        <li>
          resettable device identifiers (also known as advertising identifiers),
          such as those on mobile devices, tablets, and streaming media devices
          that include such identifiers.
        </li>
        <li>
          device and software characteristics (such as type and configuration),
          connection information including type (wifi, cellular), statistics on
          page views, referring source (for example, referral URLs), IP address
          (which may tell us your general location), browser and standard web
          server log information
        </li>
        <li>
          information collected via the use of cookies, web beacons and other
          technologies, including ad data (such as information on the
          availability and delivery of ads, the site URL, as well as the date
          and time).
        </li>
      </ul>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Information from other sources
      </Typography>
      <Typography>
        We also obtain information from other sources. We protect this
        information according to the practices described in this Privacy
        Statement, plus any additional restrictions imposed by the source of the
        data. These sources vary over time, but could include
      </Typography>
      <ul>
        <li>
          service providers that help us determine a location based on your IP
          address in order to customize our service and for other uses
          consistent with this Privacy Statement;
        </li>
        <li>
          security service providers who provide us with information to secure
          our systems, prevent fraud and help us protect the security of Arkwood
          accounts;
        </li>
        <li>
          payment service providers who provide us with payment or balance
          information, or updates to that information, based on their
          relationship with you;
        </li>
        <li>
          online and offline data providers, from which we obtain aggregated
          demographic, interest based and online advertising related data;
        </li>
        <li>
          publicly-available sources such as publicly available posts on social
          media platforms and information available through public databases
          associating IP addresses with internet service providers (ISPs);
        </li>
      </ul>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Use of Information
      </Typography>
      <Typography>
        We use information to provide, analyze, administer, enhance and
        personalize our services and marketing efforts, to manage member
        referrals, to process your registration, your orders and your payments,
        and to communicate with you on these and other topics. For example, we
        use such information to:
      </Typography>
      <ul>
        <li>
          determine your general geographic location, provide localized content,
          provide you with customized and personalized viewing recommendations
          for movies and TV shows we think will be of interest to you, determine
          your ISP to support network troubleshooting for you (we also use
          aggregated ISP information for operational and business purposes), and
          help us quickly and efficiently respond to inquiries and requests.
        </li>
        <li>
          secure our systems, prevent fraud and help us protect the security of
          accounts;
        </li>
        <li>
          prevent, detect and investigate potentially prohibited or illegal
          activities, including fraud, and to enforce our terms (such as
          determining whether and for which signup offers you are eligible and
          determining whether a particular device is permitted to use the
          account consistent with our Terms of Use);
        </li>
        <li>
          analyze and understand our audience, improve our service (including
          our user interface experiences and service performance) and optimize
          content selection, recommendation algorithms and delivery;
        </li>
        <li>
          communicate with you concerning our service so that we can send you
          news about Arkwood, details about new features and content available
          onArkwood’s, special offers, promotional announcements, consumer
          surveys, and to assist you with operational requests such as password
          reset requests. These communications may be by various methods, such
          as phone numbers, messages ,email, push notifications, text message,
          online messaging channels, and matched identifier communications
          (described below). Please see the "Your Choices" section of this
          Privacy Statement to learn how to set or change your communications
          preferences.
        </li>
      </ul>
      <Typography>
        Our recommendations system strives to predict what you will be in the
        mood to watch when you log in. However, our recommendations system does
        not infer or attach socio-demographic information (like gender or race)
        to you. We have a Help article that explains how our recommendations
        system works - spoilers: the biggest driver of recommendations is what
        you actually choose to watch through our service.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Disclosure of Information
      </Typography>
      <Typography>
        We disclose your information for certain purposes and to third parties,
        as described below:
      </Typography>
      <ul>
        <li>
          Service Providers: We use other companies, agents or contractors
          ("Service Providers") to perform services on our behalf or to assist
          us with the provision of services to you. For example, we engage
          Service Providers to provide marketing, advertising, communications,
          security, infrastructure and IT services, to customize, personalize
          and optimize our service, to provide bank account or balance
          information, to process credit card transactions or other payment
          methods, to provide customer service, to analyze and enhance data
          (including data about users' interactions with our service), and to
          process and administer consumer surveys. In the course of providing
          such services, these Service Providers may have access to your
          personal or other information. We do not authorize them to use or
          disclose your personal information except in connection with providing
          their services (which includes maintaining and improving their
          services).
        </li>
        <li>
          Promotional offers: We may offer joint promotions or programs that, in
          order for your participation, will require us to share your
          information with third parties. In fulfilling these types of
          promotions, we may share your name and other information in connection
          with fulfilling the incentive. Please note that these third parties
          are responsible for their own privacy practices.
        </li>
        <li>
          Protection of Arkwood and others: Arkwood and its Service Providers
          may disclose and otherwise use your personal and other information
          where we or they reasonably believe such disclosure is needed to (a)
          satisfy any applicable law, regulation, legal process, or governmental
          request, (b) enforce applicable terms of use, including investigation
          of potential violations thereof, (c) detect, prevent, or otherwise
          address illegal or suspected illegal activities (including payment
          fraud), security or technical issues, or (d) protect against harm to
          the rights, property or safety of Arkwood, its users or the public, as
          required or permitted by law.
        </li>
        <li>
          Business transfers: In connection with any reorganization,
          restructuring, merger or sale, or other transfer of assets, we will
          transfer information, including personal information, provided that
          the receiving party agrees to respect your personal information in a
          manner that is consistent with our Privacy Statement.
        </li>
      </ul>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Your Information and Rights
      </Typography>
      <Typography>
        You can request access to your personal information, or correct or
        update out-of-date or inaccurate personal information we hold about you.
        You may also request that we delete personal information that we hold
        about you.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Security
      </Typography>
      <Typography>
        We use reasonable administrative, logical, physical and managerial
        measures to safeguard your personal information against loss, theft and
        unauthorized access, use and modification. These measures are designed
        to provide a level of security appropriate to the risks of processing
        your personal information.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Other Websites, Platforms and Applications
      </Typography>
      <Typography>
        Arkwood’s service may be provided through and/or utilize features (such
        as voice controls) operated by third party platforms, or contain links
        to sites operated by third parties whose policies regarding the handling
        of information may differ from ours. For example, you may be able to
        access Arkwood’s service through platforms such as smart TVs, mobile
        devices, set top boxes and a number of other Internet connected devices.
        These websites and platforms have separate and independent privacy or
        data policies, privacy statements, notices and terms of use, which we
        recommend you read carefully. In addition, you may encounter third party
        applications that interact with Arkwood's service.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Children
      </Typography>
      <Typography>
        You must be at least 18 years of age or older to subscribe to Arkwood’s
        service. Minors may only use the service with the involvement,
        supervision, and approval of a parent or legal guardian.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        Changes to This Privacy Statement
      </Typography>
      <Typography>
        We will update this Privacy Statement from time to time in response to
        changing legal, regulatory or operational requirements. We will provide
        notice of any such changes (including when they will take effect) in
        accordance with law. Your continued use of the service after any such
        updates take effect will constitute acknowledgement and (as applicable)
        acceptance of those changes. If you do not wish to acknowledge or accept
        any updates to this Privacy Statement, you may cancel your use of the
        service. To see when this Privacy Statement was last updated.
      </Typography>
    </MainContainer>
  );
};

export default PrivacyTerms;
