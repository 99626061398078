import styled from "styled-components";
import {
  compose,
  variant,
  background,
  border,
  color,
  layout,
  shadow,
  space,
  textAlign,
  opacity,
  position,
  typography,
  borderRadius,
  boxShadow,
} from "styled-system";
import { theme } from "../../../../styles/theme";

const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  background-color: ${(props) =>
    props.backgroundColor || theme.colors.primary[0]};
  font-size: ${theme.fontSizes[3]};
  padding: ${theme.space[2]} ${theme.space[0]};
  border-radius: ${theme.space[5]};
  width: 100%;
  height: ${(props) => props.height || theme.sizes[10]}};  
  &:disabled {
    cursor: not-allowed;
    background: ${(props) =>
      props.disabledBackgroundColor || theme.colors.accent[1]};
    color: ${theme.colors.black[0]};
  }

  

  ${compose(
    background,
    border,
    color,
    layout,
    shadow,
    space,
    textAlign,
    opacity,
    position,
    typography,
    borderRadius,
    boxShadow
  )}

  ${variant({
    variants: {
      primary: {
        backgroundColor: theme.colors.primary[0],
      },
      secondary: {
        backgroundColor: theme.colors.secondary[0],
      },
      outlined: {
        backgroundColor: "transparent",
        border: "1px solid",
        borderColor: theme.colors.accent[0],
        color: theme.colors.secondary[0],
      },
    },
  })}
`;

export default Button;
