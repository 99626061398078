import styled from "styled-components";
import { ChevronIcon } from "../../../assets/assets";
import { Box, Button, Flex, SearchBar } from "../../components/Blocks";
import Typography from "../../components/Blocks/Typography";
import MovieCard from "../../components/Common/MovieCard";
import Layout from "../../components/Layout";
// import { theme } from "../../../styles/theme";
import { useSelector } from "react-redux";
import { CountrySelect } from "react-country-state-city/dist/cjs";
import { useState } from "react";
import { useTheme, theme, Switch } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const MovieContainer = styled(Flex)`
  margin: 20px auto;
  flex-direction: column;
  overflow: hidden;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const countries = ["Ethiopia", "Kenya", "USA", "India"];
const genre = ["Drama", "Comedy", "Horror", "Tragedy"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Filter = () => {
  const movies = useSelector((state) => state.movies.data);
  const [countryid, setCountryid] = useState(0);

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Layout>
      <Flex flexDirection={["column"]} mx={["auto"]} width={["90%"]}>
        <Flex
          alignItems={["center"]}
          flexWrap={"wrap"}
          flexDirection={["row"]}
          my={[32]}
        >
          {/* <Flex> */}
          <Flex alignItems={["center"]} mx={[2]}>
            <Typography lineHeight={[0]} fontSize={["16px"]} color={"#f4f4f4"}>
              Adult Content
            </Typography>
            <Switch
              checked={true}
              onChange={() => console.log("adult content")}
              inputProps={{ "aria-label": "controlled" }}
              color="warning"
            />
          </Flex>
          <Flex alignItems={["center"]} mx={[2]}>
            <Typography lineHeight={[0]} fontSize={["16px"]} color={"#f4f4f4"}>
              Free
            </Typography>
            <Switch
              checked={true}
              onChange={() => console.log("adult content")}
              inputProps={{ "aria-label": "controlled" }}
              color="warning"
            />
          </Flex>
          <Flex alignItems={["center"]} mx={[2]}>
            <Typography lineHeight={[0]} fontSize={["16px"]} color={"#f4f4f4"}>
              Trending
            </Typography>
            <Switch
              checked={true}
              onChange={() => console.log("adult content")}
              inputProps={{ "aria-label": "controlled" }}
              color="warning"
            />
          </Flex>
          <FormControl
            sx={{
              m: 1,
              width: 300,
              border: "2px solid #f4f4f4",
              borderRadius: "3px",
            }}
          >
            <Select
              sx={{ color: "#f4f4f4", border: "#f4f4f4" }}
              multiple
              displayEmpty
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Country</em>;
                }

                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>Country</em>
              </MenuItem>
              {countries.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              m: 1,
              width: 300,
              border: "2px solid #f4f4f4",
              borderRadius: "3px",
            }}
          >
            <Select
              sx={{ color: "#f4f4f4" }}
              multiple
              displayEmpty
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Genre</em>;
                }

                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem sx={{ color: "#f4f4f4" }} disabled value="">
                <em>Genre</em>
              </MenuItem>
              {genre.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* </Flex> */}

          <Box width={["100%", "100%", "160px"]}>
            <Button>Filter</Button>
          </Box>
        </Flex>
        <MovieContainer width={"100%"}>
          <Box
            // backgroundColor={theme.colors.black[3]}
            alignItems="center"
            justifyContent="center"
            mx="auto"
            width={["100%"]}
          >
            <Flex justifyContent="space-between" flexWrap="wrap" mx="auto">
              {movies?.results?.map((movie) => (
                <MovieCard movie={movie} />
              ))}
            </Flex>
            <Box height="140px" width="80vw" mx="auto" mt={64} mb={128}>
              <img
                height="200px"
                width="100%"
                src="https://images.unsplash.com/photo-1495422964407-28c01bf82b0d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
              />
            </Box>
          </Box>
        </MovieContainer>
      </Flex>
    </Layout>
  );
};

export default Filter;
