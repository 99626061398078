import { call, put, takeLatest } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import { getCastsStart, setCastsData, getCastsError } from ".";
import apiRoutes from "../../app/API/routes";

function* workCastsSaga(action) {
  try {
    // Make an API call to register the user
    const response = yield call(makeAPICall, {
      route: apiRoutes.casts.base,
      method: "GET",
    });
    console.log(response, "response");
    yield put(setCastsData(response));
  } catch (error) {
    console.log(error);
    yield put(getCastsError(error.message));
  }
}

function* castsWatcherSaga() {
  yield takeLatest(getCastsStart.type, workCastsSaga);
}

export default castsWatcherSaga;
