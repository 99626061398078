import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./app/pages/Homepage";
import Login from "./app/pages/Login";
import MovieDetail from "./app/pages/MovieDetail";
import Signup from "./app/pages/Signup";
import Onboarding from "./app/pages/Onboarding";
import Filter from "./app/pages/Filter";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import AuthProvider from "./app/contexts/AuthProvider";
import withAuth from "./app/hocs/WithAuth";
import ArtistDetail from "./app/pages/ArtistDetail";
import SubscriptionPage from "./app/pages/Subscription";
import CheckoutPage from "./app/pages/Checkout";
import AgreementTerm from "./app/pages/Documents/AgreementTerms";
import PrivacyTerms from "./app/pages/Documents/PrivacyPolicy";
import { PersistGate } from "redux-persist/lib/integration/react";
import UserDetail from "./app/pages/UserDetail";

const ProtectedLogin = withAuth(Login);
const ProtectedSignup = withAuth(Signup);
const ProtectedMovie = withAuth(MovieDetail);
const ProtectedHome = withAuth(Homepage);
const ProtectedFilter = withAuth(Filter);
const ProtectedOnboard = withAuth(Onboarding);
const ProtectedArtistDetail = withAuth(ArtistDetail);
const ProtectedSubscriptionPage = withAuth(SubscriptionPage);
const ProtectedCheckoutPage = withAuth(CheckoutPage);
const ProtectedAgreementTerm = withAuth(AgreementTerm);
const ProtectedPrivacyTerms = withAuth(PrivacyTerms);
const ProtectedUserDetail = withAuth(UserDetail);

// const routes = [
//   {
//     path: "/login",
//     element: <Login />,
//   },
//   {
//     path: "/signup",
//     element: <ProtectedSignup />,
//   },
//   {
//     path: "/movie/:id",
//     element: <ProtectedMovie />,
//   },
//   {
//     path: "/movies",
//     element: <ProtectedHome />,
//   },
//   {
//     path: "/filter",
//     element: <ProtectedFilter />,
//   },
//   {
//     path: "/*",
//     element: <ProtectedOnboard />,
//   },
//   {
//     path: "/actor/:id",
//     element: <ProtectedArtistDetail />,
//   },
//   {
//     path: "/subscriptions",
//     element: <ProtectedSubscriptionPage />,
//   },
//   {
//     path: "/checkout",
//     element: <ProtectedCheckoutPage />,
//   },
//   {
//     path: "/agreementTerms",
//     element: <ProtectedAgreementTerm />,
//   },
//   {
//     path: "/privacyPolicy",
//     element: <ProtectedPrivacyTerms />,
//   },
//   {
//     path: "/me",
//     element: <ProtectedUserDetail />,
//   },
// ];

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/movie/:id",
    element: <MovieDetail />,
  },
  {
    path: "/movies",
    element: <Homepage />,
  },
  {
    path: "/filter",
    element: <Filter />,
  },
  {
    path: "/*",
    element: <Onboarding />,
  },
  {
    path: "/actor/:id",
    element: <ArtistDetail />,
  },
  {
    path: "/subscriptions",
    element: <SubscriptionPage />,
  },
  {
    path: "/checkout",
    element: <CheckoutPage />,
  },
  {
    path: "/agreementTerms",
    element: <AgreementTerm />,
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyTerms />,
  },
  {
    path: "/me",
    element: <UserDetail />,
  },
];

function App() {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      {/* <AuthProvider> */}
      <BrowserRouter>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
      {/* </AuthProvider> */}
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
