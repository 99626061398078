import { takeLatest, call, put } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import { registerUserFailure, registerUserStart, registerUserSuccess } from ".";
import apiRoutes from "../../app/API/routes";

function* registerUser(action) {
  try {
    // Make an API call to register the user
    const response = yield call(makeAPICall, {
      route: apiRoutes.user.signUp,
      method: "POST",
      body: action.payload,
    });
    // Dispatch success action with the registered user data

    yield put(registerUserSuccess(response));
  } catch (error) {
    // Dispatch failure action with the error message
    yield put(registerUserFailure(error.message));
  }
}

export function* watchRegisterUser() {
  yield takeLatest(registerUserStart.type, registerUser);
}
