import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: [],
};

const castSlice = createSlice({
  name: "casts",
  initialState,
  reducers: {
    getCastsStart: (state) => {
      state.loading = true;
    },
    setCastsData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    getCastsError: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = action.payload;
    },
  },
});

export const { getCastsStart, setCastsData, getCastsError } = castSlice.actions;

export default castSlice.reducer;
