import styled from "styled-components";
import { Flex } from "../Blocks";
import Typography from "../Blocks/Typography";
import { theme } from "../../../styles/theme";
import React, { memo } from "react";

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;

const SwitchButton = ({ value, active, handler }) => {
  return (
    <StyledFlex
      borderRadius={[18]}
      alignItems={"center"}
      justifyContent={"center"}
      px={4}
      py={4}
      backgroundColor={value === active ? theme.colors.primary[0] : "none"}
      onClick={() => handler(value)}
      height={[36]}
      mx={[12]}
    >
      <Typography fontWeight={["inherit"]} color={["inherit"]} lineHeight={[0]}>
        {value}
      </Typography>
    </StyledFlex>
  );
};

export default React.memo(SwitchButton);
