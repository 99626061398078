import styled from "styled-components";
import { Button, Flex } from "../../Blocks";
import Placeholder from "../../../../assets/images/thumbnail.png";
import { theme } from "../../../../styles/theme";
import Typography from "../../Blocks/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserFromLocalStorage } from "../../../../utils/functions";
import { getMoviesStart } from "../../../../Slice/Movies";
import { useNavigate } from "react-router-dom";
import Modal from "../../Blocks/Modal";
import ReactPlayer from "react-player";
import SwitchButton from "../../Common/SwitchButton";
import { CloseIcon, StarIcon } from "../../../../assets/assets";
import useMoviePayment from "../../../hooks/useMoviePayment";
import Chapa from "../../../components/Checkout/Chapa";
import Stripe from "../../../components/Checkout/Stripe";

const TrendingContainer = styled(Flex)`
  position: relative;
  background-size: 100%;
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  overflow: hidden; /* Ensure the gradient doesn't overflow */
  &:hover {
    border: 1px solid rgba(241, 131, 22, 1);
    background-size: 100%;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
`;

const MovieData = styled(Flex)`
  position: absolute;
  bottom: 5%;
  left: 5%;
  flex-direction: column;
  z-index: 4;
`;

const MovieTitle = styled.p`
  font-size: 24px;
  line-height: 0px;
  font-weight: bold;
  z-index: 10;
  width: 100%;
`;

const Genre = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 0;
  color: ${theme.colors.primary[0]}
  z-index: 10;
`;

const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const MainBtns = styled(Flex)`
  gap: 1rem;
`;

const Trending = ({ Movie }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentMethodVisible, setIsPaymentMethodVisible] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState("Chapa");
  const isPaid = useMoviePayment(Movie?.id);

  const paymentMethods = ["Chapa", "Stripe"];
  const navigate = useNavigate();

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleWatchMovie = (id) => {
    if (isPaid) {
      navigate(`/movie/${id}`);
    } else {
      setIsPaymentMethodVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleLanguageToggle = (input) => {
    setActivePaymentMethod(input);
  };

  const renderPaymentMethod = () => {
    if (activePaymentMethod === "Stripe") {
      return <Stripe type={"movie"} id={Movie?.id} />;
    } else {
      return <Chapa type={"movie"} id={Movie?.id} />;
    }
  };

  return (
    <TrendingContainer
      mx={2}
      borderRadius={"3px"}
      color="#fff"
      minHeight={"400px"}
      minWidth={["98vw", "30vw"]}
      onClick={handleCardClick}
    >
      <div style={{ position: "relative", width: "100%", height: "400px" }}>
        <img
          src={Movie?.poster?.high_quality}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: "14px",
          }}
          alt="Trending Image"
        />
      </div>

      <GradientOverlay />
      <MovieData>
        <MovieTitle>{Movie?.name}</MovieTitle>
        <Flex flexDirection="column">
          {Movie?.genre?.map((genre) => (
            <Genre key={genre.id}>{genre.name}</Genre>
          ))}

          <Typography
            lineHeight={"0px"}
            color={theme.colors.primary[0]}
            fontSize={"13px"}
            fontWeight={"900"}
          >
            {Movie?.language?.map((lang) => (
              <em key={lang.id} style={{ marginRight: "8px" }}>
                {lang.language}
              </em>
            ))}
          </Typography>
        </Flex>
      </MovieData>

      <Modal onClose={handleClose} open={isModalOpen}>
        {!isPaymentMethodVisible ? (
          <>
            <CloseBtn onClick={handleClose}>
              <CloseIcon height={"24px"} width={"24px"} />
            </CloseBtn>
            <ReactPlayer width={"100%"} url={Movie?.trailer} />
            <MainBtns px={[3]} py={[3]}>
              <Button
                onClick={() => handleWatchMovie(Movie?.id)}
                width={"100%"}
                style={{ marginTop: "1rem" }}
              >
                Watch Movie
              </Button>
              <Button
                width={"100%"}
                style={{
                  marginTop: "1rem",
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.colors.white[0]}`,
                }}
                onClick={handleClose}
              >
                Change Movie
              </Button>
            </MainBtns>
            <Flex backgroundColor={theme.colors.secondary[3]}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={["100%"]}
                mx={[4]}
              >
                <Typography
                  fontFamily={"Protest Revolution, sans-serif"}
                  color={theme.colors.white[0]}
                  fontSize={["32px"]}
                >
                  {Movie?.title}
                </Typography>

                <Flex
                  py={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexDirection={["column"]}
                >
                  <Typography
                    lineHeight={0}
                    color={theme.colors.white[0]}
                    fontSize={["12px"]}
                  >
                    {Movie?.made_in}
                  </Typography>
                  <Typography
                    lineHeight={0}
                    color={theme.colors.white[0]}
                    fontSize={["12px"]}
                  >
                    {Movie?.genre?.map((genre) => (
                      <em key={genre.id} style={{ marginRight: "10px" }}>
                        {genre?.name}
                      </em>
                    ))}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>

            <Flex mx={[4]} flexDirection={"column"} py={[4]}>
              <Flex>
                <StarIcon style={{ marginRight: "10px" }} />
                <StarIcon style={{ marginRight: "10px" }} />
                <StarIcon style={{ marginRight: "10px" }} />
              </Flex>

              <Typography color={theme.colors.white[0]} fontSize={["16px"]}>
                {Movie?.description}
              </Typography>
            </Flex>
          </>
        ) : (
          <Flex
            px={0}
            alignItems={["center"]}
            justifyContent={["space-between"]}
            flexDirection={["column"]}
            width={["100%"]}
          >
            <CloseBtn onClick={handleClose}>
              <CloseIcon height={"24px"} width={"24px"} />
            </CloseBtn>
            <Flex
              my={4}
              px={[0]}
              alignItems={["center"]}
              justifyContent={["space-between"]}
              borderRadius={[12]}
              height={[64]}
              backgroundColor={[theme.colors.black[2]]}
              flexDirection={["row"]}
            >
              {paymentMethods?.map((item, index) => (
                <SwitchButton
                  options={paymentMethods}
                  value={item}
                  active={activePaymentMethod}
                  handler={handleLanguageToggle}
                />
              ))}
            </Flex>
            <Flex backgroundColor={theme.colors.white[0]} width={"100%"}>
              {renderPaymentMethod()}
            </Flex>
          </Flex>
        )}
      </Modal>
    </TrendingContainer>
  );
};

export default Trending;
