import { Flex } from "../Flex";
import NoMoveAnimatioin from "../../../../assets/animations/NoMovie.gif";
import Typography from "../Typography";
import { theme } from "../../../../styles/theme";

const NoMovieFound = () => {
  return (
    <Flex mx={["auto"]} alignItems={["center"]} flexDirection={["column"]}>
      <img src={NoMoveAnimatioin} height={["320px"]} />
      <Typography
        color={[theme.colors.white[0]]}
        fontSize={["24px", "32px"]}
        fontWeight={[800]}
      >
        No movies found
      </Typography>
    </Flex>
  );
};

export default NoMovieFound;
