import styled from "styled-components";
import { Button, Flex } from "../Blocks";
import React, { useState } from "react";
import Typography from "../Blocks/Typography";
import { theme } from "../../../styles/theme";
import { useNavigate } from "react-router-dom";
import { CloseIcon, StarIcon } from "../../../assets/assets";
import ReactPlayer from "react-player";
import Modal from "../Blocks/Modal";
import Stripe from "../Checkout/Stripe";
import Chapa from "../Checkout/Chapa";
import SwitchButton from "./SwitchButton";
import useMoviePayment from "../../hooks/useMoviePayment";

const TrendingContainer = styled(Flex)`
  background-size: 100%;
  border: 1px solid grey;
  margin: 12px 0;
  &:hover {
    border: 1px solid ${theme.colors.primary[0]};
  }
`;

const MovieTitle = styled.p`
  font-size: 14px;
  // line-height: 0px;
  color: ${theme.colors.white[0]};
`;

const Tags = styled.p`
  font-size: 12px;
  color: ${theme.colors.white[2]};
`;

const Genre = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 0;
  color: ${theme.colors.primary[0]}
  z-index: 10;
`;

const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const MainBtns = styled(Flex)`
  gap: 1rem;
`;

const MovieCard = ({ movie }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentMethodVisible, setIsPaymentMethodVisible] = useState(false);
  const isPaid = useMoviePayment(movie?.id);

  const [activePaymentMethod, setActivePaymentMethod] = React.useState("Chapa");

  const paymentMethods = ["Chapa", "Stripe"];

  const navigate = useNavigate();

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleWatchMovie = (id) => {
    if (isPaid) {
      navigate(`/movie/${id}`);
    } else {
      setIsPaymentMethodVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleLanguageToggle = (input) => {
    setActivePaymentMethod(input);
  };

  const renderPaymentMethod = () => {
    if (activePaymentMethod === "Stripe") {
      return <Stripe type={"movie"} id={movie?.id} />;
    } else {
      return <Chapa type={"movie"} id={movie?.id} />;
    }
  };

  return (
    <Flex mx={2} flexDirection="column" onClick={handleCardClick}>
      <TrendingContainer
        borderRadius={"6px"}
        color={theme.colors.white[0]}
        height={"250px"}
        width={["150px", "165px", "180px"]}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <img
            src={movie?.poster?.low_quality}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            alt="Trending Image"
          />
        </div>
      </TrendingContainer>
      <Flex flexDirection="column" width={["150px", "165px", "180px"]}>
        <Flex alignItems="center" justifyContent="space-between">
          <Tags>{movie?.release_date}</Tags>
        </Flex>
        <MovieTitle>{movie?.title}</MovieTitle>
      </Flex>
      <Modal onClose={handleClose} open={isModalOpen}>
        {!isPaymentMethodVisible ? (
          <>
            <CloseBtn onClick={handleClose}>
              <CloseIcon height={"24px"} width={"24px"} />
            </CloseBtn>
            <ReactPlayer width={"100%"} url={movie?.trailer} />
            <MainBtns px={[3]} py={[3]}>
              <Button
                onClick={() => handleWatchMovie(movie?.id)}
                width={"100%"}
                style={{ marginTop: "1rem" }}
              >
                Watch Movie
              </Button>
              <Button
                width={"100%"}
                style={{
                  marginTop: "1rem",
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.colors.white[0]}`,
                }}
                onClick={handleClose}
              >
                Change Movie
              </Button>
            </MainBtns>
            <Flex backgroundColor={theme.colors.secondary[3]}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={["100%"]}
                mx={[4]}
              >
                <Typography
                  fontFamily={"Protest Revolution, sans-serif"}
                  color={theme.colors.white[0]}
                  fontSize={["32px"]}
                >
                  {movie?.title}
                </Typography>

                <Flex
                  py={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexDirection={["column"]}
                >
                  <Typography
                    lineHeight={0}
                    color={theme.colors.white[0]}
                    fontSize={["12px"]}
                  >
                    {movie?.made_in}
                  </Typography>
                  <Typography
                    lineHeight={0}
                    color={theme.colors.white[0]}
                    fontSize={["12px"]}
                  >
                    {movie?.genre?.map((genre) => (
                      <em key={genre.id} style={{ marginRight: "10px" }}>
                        {genre?.name}
                      </em>
                    ))}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>

            <Flex mx={[4]} flexDirection={"column"} py={[4]}>
              <Flex>
                <StarIcon style={{ marginRight: "10px" }} />
                <StarIcon style={{ marginRight: "10px" }} />
                <StarIcon style={{ marginRight: "10px" }} />
              </Flex>

              <Typography color={theme.colors.white[0]} fontSize={["16px"]}>
                {movie?.description}
              </Typography>
            </Flex>
          </>
        ) : (
          <Flex
            px={0}
            alignItems={["center"]}
            justifyContent={["space-between"]}
            flexDirection={["column"]}
            width={["100%"]}
          >
            <CloseBtn onClick={handleClose}>
              <CloseIcon height={"24px"} width={"24px"} />
            </CloseBtn>
            <Flex
              my={4}
              px={[0]}
              alignItems={["center"]}
              justifyContent={["space-between"]}
              borderRadius={[12]}
              height={[64]}
              backgroundColor={[theme.colors.black[2]]}
              flexDirection={["row"]}
              color={[theme.colors.white[0]]}
            >
              {paymentMethods?.map((item, index) => (
                <SwitchButton
                  options={paymentMethods}
                  value={item}
                  active={activePaymentMethod}
                  handler={handleLanguageToggle}
                />
              ))}
            </Flex>
            <Flex backgroundColor={theme.colors.white[0]} width={"100%"}>
              {renderPaymentMethod()}
            </Flex>
          </Flex>
        )}
      </Modal>
    </Flex>
  );
};

export default MovieCard;
