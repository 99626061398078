import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: [],
};

const moviesSlice = createSlice({
  name: "movies",
  initialState,
  reducers: {
    getMoviesStart: (state, action) => {
      state.loading = true;
    },
    setMoviesData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    getMoviesError: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = action.payload;
    },
  },
});

export const { getMoviesStart, setMoviesData, getMoviesError } =
  moviesSlice.actions;

export default moviesSlice.reducer;
