import { call, put, takeLatest } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import { login, loginFailed, loginSuccess } from ".";
import apiRoutes from "../../app/API/routes";
import { localStorageKeys } from "../../utils/constants";

function* workAuthSaga(action) {
  try {
    // Make an API call to register the user
    const response = yield call(makeAPICall, {
      route: apiRoutes.user.login,
      method: "POST",
      body: action.payload,
    });

    localStorage.setItem(localStorageKeys.user, JSON.stringify(response));
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(loginFailed(error.message));
    console.log(error);
  }
}

function* authWatcherSaga() {
  yield takeLatest(login.type, workAuthSaga);
}

export default authWatcherSaga;
