const bases = {
  user: "user/auth",
  movies: "stream/movies",
  cast: "casts/cast",
  subscriptions: "subscription",
};

const user = {
  base: bases.user,
  signUp: `${bases.user}/users/`,
  login: `${bases.user}/jwt/create/`,
  refresh: `${bases.user}/jwt/refresh/`,
  activateAccount: `${bases.user}/activateAccount`,
  me: `${bases.user}/users/me/`,
  update: `user/profile`,
};

const movies = {
  base: bases.movies,
  video: 'stream/VideoPlayerPage'
};

const casts = {
  base: bases.cast,
};

const subscriptions = {
  base: bases.subscriptions,
  packages: `${bases.subscriptions}/package`,
  stripePackage: `${bases.subscriptions}/stripe/subscription/checkout`,
  stripeMovie: `${bases.subscriptions}/stripe/movie/checkout`,
  chapaPackage: `${bases.subscriptions}/chapa/subscription/checkout`,
  chapaMovie: `${bases.subscriptions}/chapa/movie/checkout`,
};

const apiRoutes = { user, movies, casts, subscriptions };

export default apiRoutes;
