import styled from "styled-components";
// import { DesktopIcon } from "../../../assets/assets";
import { Flex } from "../../components/Blocks/Flex";
import TextInput from "../../components/Blocks/TextInput";
import Typography from "../../components/Blocks/Typography";
import { theme } from "../../../styles/theme";
// import { ReactCountryDropdown } from "react-country-dropdown";
// import "react-country-dropdown/dist/index.css";
import { Box } from "../../components/Blocks";
import { useState } from "react";
import { SignupSchema } from "../../../validation/UserRegistration";
// import { makeAPICall } from "../../API/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { registerUserStart } from "../../../Slice/Signup";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Country } from "country-state-city";
import Select from "react-select";

const LoginButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${theme.fontSizes[5]};
`;

const StyledFlex = styled(Flex)`
  border: 0.5px solid ${theme.colors.white[0]};
  border-radius: 8px;
`;

const Signup = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [invalidForm, setIsInvalidForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const userRegistered = useSelector((state) => state.signUp.success);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: 0,
    date_of_birth: "",
    password: "",
    re_password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const createUser = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("file");
    const file = fileInput.files[0];

    let userData = {
      username: formData.username,
      email: formData.email,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone_number: formData.phone_number,
      date_of_birth: formData.date_of_birth,
      country: selectedCountry.isoCode,
      password: formData.password,
      re_password: formData.re_password,
    };

    let userForm = new FormData();
    userForm.append("username", formData.username);
    userForm.append("email", formData.email);
    userForm.append("first_name", formData.first_name);
    userForm.append("last_name", formData.last_name);
    userForm.append("phone_number", formData.phone_number);
    userForm.append("date_of_birth", formData.date_of_birth);
    userForm.append("country", selectedCountry.isoCode);
    userForm.append("password", formData.password);
    userForm.append("re_password", formData.re_password);
    userForm.append("profile_picture", file);

    const isValid = await SignupSchema.isValid(userData);
    if (isValid) {
      dispatch(registerUserStart(userForm));
      if (userRegistered) {
        navigate("/login");
      }
    } else {
      setIsInvalidForm(true);
    }
  };

  return (
    <Flex
      height={["fit", "100vh"]}
      alignItems={"center"}
      justifyContent={"center"}
      backgroundColor={"#12161F"}
    >
      <Flex
        py={4}
        px={[4, 6]}
        alignItems={["center"]}
        justifyContent={["space-between"]}
        flexDirection={["column"]}
        width={["98%", "96%", "96%", "70%"]}
      >
        <Flex
          alignItems={["center"]}
          justifyContent={["space-between"]}
          flexDirection={["column", "column", "row", "row"]}
          width={["100%"]}
        >
          <Flex
            alignItems={["flex-start"]}
            justifyContent={["space-between"]}
            display={["none", "none", "flex"]}
            flexDirection={["column"]}
            mt={["20%"]}
            height={["600px"]}
            width={["100%", "100%", "65%"]}
          >
            <Flex flexDirection={"column"} pr={48}>
              <Typography
                color={theme.colors.white[0]}
                fontWeight={["800"]}
                fontSize={["24px"]}
              >
                Home of African Entertainment.
              </Typography>
              <Typography
                mt={"-20px"}
                color={theme.colors.white[0]}
                fontWeight={["800"]}
                fontSize={["24px"]}
              >
                {" "}
                African Entertainment.
              </Typography>

              <Typography
                lineHeight={["19px"]}
                color={theme.colors.white[1]}
                fontWeight={["300"]}
                fontSize={["14px"]}
              >
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200 Latin words, combined with a handful of
                model sentence structures, to generate Lorem Ipsum which looks
                reasonable. The generated Lorem Ipsum is therefore always free
                from repetition, injected humour, or non-characteristic words
                etc.
              </Typography>
            </Flex>
            <Box
              backgroundColor={theme.colors.white[1]}
              borderRadius={10}
              mr={48}
              padding={16}
            >
              <Typography
                lineHeight={["14px"]}
                color={theme.colors.black[1]}
                fontWeight={["600"]}
                fontSize={["14px"]}
              >
                By signing up, you agree to the Terms & Conditions and Privacy
                Policy
              </Typography>
            </Box>
          </Flex>
          <StyledFlex
            display={["block", "block", "block"]}
            flexDirection={["column"]}
            padding={[32]}
            mt={["20%"]}
            width={["100%", "100%", "60%"]}
          >
            <Typography
              color={theme.colors.white[0]}
              fontWeight={["600"]}
              fontSize={["18px"]}
            >
              Get Started with us
            </Typography>
            <form onSubmit={createUser}>
              <TextInput
                placeholder="Username"
                p={2}
                name="username"
                my={["10px"]}
                width={["100%"]}
                value={formData.username}
                onChange={handleInputChange}
              />
              <TextInput
                placeholder="Email"
                p={2}
                name="email"
                my={["10px"]}
                width={["100%"]}
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextInput
                placeholder="First Name"
                p={2}
                name="first_name"
                my={["10px"]}
                width={["100%"]}
                value={formData.first_name}
                onChange={handleInputChange}
              />
              <TextInput
                placeholder="Last Name"
                p={2}
                name="last_name"
                my={["10px"]}
                width={["100%"]}
                value={formData.last_name}
                onChange={handleInputChange}
              />
              <input
                type="file"
                id="file"
                style={{
                  height: "48px",
                  backgroundColor: "#f4f4f4",
                  width: "100%",
                  padding: "14px",
                  margin: "8px 0",
                  borderRadius: "4px",
                }}
              />

              {/* <Flex alignItems={["flex-end"]}>
                <Box height={["52px"]} width={["120px"]}>
                  <ReactCountryDropdown
                    onSelect={handleSelect}
                    countryCode="ET"
                  />
                </Box>
                <TextInput
                  placeholder="Phone Number"
                  px={18}
                  borderRadius={24}
                  name="phone_number"
                  my={["10px"]}
                  width={["100%"]}
                  value={formData.phone_number}
                  onChange={handleInputChange}
                />
              </Flex> */}
              <Box
                p={2}
                my={["10px"]}
                backgroundColor={"#fff"}
                borderRadius={"2px"}
              >
                <Select
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                />
              </Box>
              <Box
                p={3}
                my={["10px"]}
                backgroundColor={"#fff"}
                borderRadius={"2px"}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </Box>

              <TextInput
                placeholder="Date of Birth"
                p={2}
                name="date_of_birth"
                my={["10px"]}
                width={["100%"]}
                value={formData.date_of_birth}
                onChange={handleInputChange}
              />
              <TextInput
                placeholder="Password"
                p={2}
                name="password"
                my={["10px"]}
                width={["100%"]}
                value={formData.password}
                onChange={handleInputChange}
              />
              <TextInput
                placeholder="Confirm Password"
                p={2}
                name="re_password"
                my={["10px"]}
                mb={["26px"]}
                width={["100%"]}
                value={formData.re_password}
                onChange={handleInputChange}
              />
              <LoginButton type="submit" width={"100%"}>
                Sign Up
              </LoginButton>
            </form>
            <Link
              to="/login"
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textDecorationColor: theme.colors.primary[0],
              }}
            >
              <Typography textAlign={"center"} color={theme.colors.primary[0]}>
                Login instead
              </Typography>
            </Link>
          </StyledFlex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Signup;
