import styled from "styled-components";
import { Flex } from "../../components/Blocks/Flex";
import Typography from "../../components/Blocks/Typography";
import { theme } from "../../../styles/theme";
import Layout from "../../components/Layout";
import React, { useState, useEffect } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SwitchButton from "../../components/Common/SwitchButton";
import { stripePromise } from "../../../utils/constants";
import Stripe from "../../components/Checkout/Stripe";
import Chapa from "../../components/Checkout/Chapa";

const LoginButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${theme.fontSizes[5]};
`;

const StyledFlex = styled(Flex)`
  // background: linear-gradient(to right, #16222a, #3a6073);
  background: #fff;
  border-radius: 8px;
`;

const CheckoutPage = () => {
  const [activePaymentMethod, setActivePaymentMethod] = useState("Stripe");

  const paymentMethods = ["Stripe", "Chapa"];

  const handleLanguageToggle = (input) => {
    setActivePaymentMethod(input);
  };

  const renderPaymentMethod = () => {
    if (activePaymentMethod === "Stripe") {
      return <Stripe />;
    } else {
      return <Chapa />;
    }
  };
  return (
    <Layout>
      <Flex
        minHeight={["fit", "100vh"]}
        my={[4]}
        py={[2]}
        alignItems={"center"}
        justifyContent={"center"}
        backgroundColor={"#12161F"}
      >
        <StyledFlex
          py={4}
          px={[4, 6]}
          alignItems={["center"]}
          justifyContent={["space-between"]}
          flexDirection={["column"]}
          width={["98%", "96%", "96%", "70%"]}
          height={["fit"]}
        >
          <Flex
            px={[0]}
            alignItems={["center"]}
            justifyContent={["space-between"]}
            borderRadius={[24]}
            height={[48]}
            backgroundColor={[theme.colors.black[2]]}
            flexDirection={["row"]}
          >
            {paymentMethods?.map((item, index) => (
              <SwitchButton
                options={paymentMethods}
                value={item}
                active={activePaymentMethod}
                handler={handleLanguageToggle}
              />
            ))}
          </Flex>
          {renderPaymentMethod()}
        </StyledFlex>
      </Flex>
    </Layout>
  );
};

export default CheckoutPage;
