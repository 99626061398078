import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: null,
    isLoading: false,
    error: null,
    success: false,
  },
  reducers: {
    login: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.userData = action.payload;
      state.success = true;
      state.isLoading = false;
      state.error = null;
    },
    clearUserData: (state) => {
      state.userData = null;
      state.success = false;
    },

    loginFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { login, loginSuccess, loginFailed, clearUserData } =
  authSlice.actions;
export default authSlice.reducer;
