import React from "react";
import ReactDom from "react-dom";
import { theme } from "../../../../styles/theme";
import styled from "styled-components";
import { Flex } from "../Flex";

const MODAL_STYLES = {
  position: "absolute",
  backgroundColor: `${theme.colors.accent[4]}`,
  zIndex: 999,
  width: "45%",
  borderRadius: ".5em",
  top: "2rem",
};
const OVERLAY_STYLE = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0, .8)",
  zIndex: 999,
  overflowY: "auto",
};

const ModalContainer = styled(Flex)`
  position: absolute;
  background-color: ${theme.colors.accent[4]};
  z-index: 999;
  border-radius: 0.5em;
  top: 2rem;
  flex-direction: column;
`;

const Modal = ({ open, children, onClose }) => {
  if (!open) return null;

  const handleClose = (event) => {
    event.stopPropagation();
    // onClose();
  };

  return (
    <>
      <div onClick={handleClose} style={OVERLAY_STYLE}>
        <ModalContainer width={["100%", "90%", "80%", "45%"]}>
          {children}
        </ModalContainer>
      </div>
    </>
  );
};
export default Modal;
