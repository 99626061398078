import { Flex } from "../../Blocks";
import { useDispatch, useSelector } from "react-redux";
import { getUserFromLocalStorage } from "../../../../utils/functions";
import React, { useEffect, useRef, useState } from "react";
import { getTrendingMoviesStart } from "../../../../Slice/Trending";
import HeroSingle from "./HeroSingle";

const HeroSection = () => {
  const containerRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);
  const dispatch = useDispatch();

  const trendingMovies = useSelector((state) => state.trending.data);

  useEffect(() => {
    const container = containerRef.current;
    let scrollInterval;

    const startScrolling = () => {
      scrollInterval = setInterval(() => {
        setScrolling(true);
        container.scrollLeft += 1750;
      }, 2500);
    };

    const stopScrolling = () => {
      clearInterval(scrollInterval);
      setScrolling(false);
    };

    container.addEventListener("mouseenter", stopScrolling);
    container.addEventListener("mouseleave", startScrolling);

    startScrolling();

    return () => {
      container.removeEventListener("mouseenter", stopScrolling);
      container.removeEventListener("mouseleave", startScrolling);
      stopScrolling();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const user = getUserFromLocalStorage();
      if (user) {
        dispatch(getTrendingMoviesStart({ is_trainding: true }));
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div
      ref={containerRef}
      className={`scroll-container ${scrolling ? "scrolling" : ""}`}
    >
      <Flex id={"container"} height={["100vh"]}>
        {trendingMovies?.results?.map((Movie, index) => (
          <HeroSingle index={index} movie={Movie} />
        ))}
      </Flex>
      <style jsx>{`
        .scroll-container {
          overflow-x: auto;
          white-space: nowrap;
          transition: scroll-left 0.2s ease-in-out;
        }

        .scroll-container.scrolling {
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;
        }
      `}</style>
    </div>
  );
};

export default HeroSection;

// import React, { useEffect, useRef, useState } from "react";
// import { Flex } from "../../Blocks";
// import Trending from "./Trending";
// import { useDispatch, useSelector } from "react-redux";

// const HeroSection = ({ children }) => {
//   const containerRef = useRef(null);
//   const [scrolling, setScrolling] = useState(false);

//   const dispatch = useDispatch();

//   const trendingMovies = useSelector((state) => state.trending.data);

//   useEffect(() => {
//     const container = containerRef.current;
//     let scrollInterval;

//     const startScrolling = () => {
//       scrollInterval = setInterval(() => {
//         setScrolling(true);
//         container.scrollLeft += 1750;
//       }, 3000);
//     };

//     const stopScrolling = () => {
//       clearInterval(scrollInterval);
//       setScrolling(false);
//     };

//     useEffect(() => {
//       const fetchData = async () => {
//         const user = getUserFromLocalStorage();
//         if (user) {
//           dispatch(getTrendingMoviesStart({ is_trainding: true }));
//         }
//       };

//       fetchData();
//     }, [dispatch]);

//     container.addEventListener("mouseenter", stopScrolling);
//     container.addEventListener("mouseleave", startScrolling);

//     startScrolling();

//     return () => {
//       container.removeEventListener("mouseenter", stopScrolling);
//       container.removeEventListener("mouseleave", startScrolling);
//       stopScrolling();
//     };
//   }, []);

//   return (
//     <div
//       ref={containerRef}
//       className={`scroll-container ${scrolling ? "scrolling" : ""}`}
//     >
//       <Flex id={"container"} height={["100vh"]}>
//         {trendingMovies?.results?.map((Movie, index) => (
//           <HeroSingle index={index} movie={Movie} />
//         ))}
//       </Flex>
//       <style jsx>{`
//         .scroll-container {
//           overflow-x: auto;
//           white-space: nowrap;
//           transition: scroll-left 0.2s ease-in-out;
//         }

//         .scroll-container.scrolling {
//           scroll-behavior: smooth;
//           scroll-snap-type: x mandatory;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default HeroSection;
