import { theme } from "../../../styles/theme";
import { Box, Flex } from "../../components/Blocks";
import Typography from "../../components/Blocks/Typography";
import Layout from "../../components/Layout";

import RecommendedImage from "../../../assets/images/recommended.png";

const ArtistDetail = () => {
  return (
    <Layout>
      <Flex flexDirection={["column"]} mx={["auto"]} width={["90%"]}>
        <Box
          backgroundColor={[theme.primary]}
          minWidth={["100%"]}
          minHeight={["80vh"]}
          py={32}
        >
          <iframe
            src="https://player.vimeo.com/video/880468870?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=279046"
            width="100%"
            height="700"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            title="Yesuf-Abeba"
          ></iframe>
        </Box>

        <Box height="140px" width="80vw" mx="auto" mt={12} mb={154}>
          <img
            height="200px"
            width="100%"
            src="https://images.unsplash.com/photo-1495422964407-28c01bf82b0d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
          />
        </Box>
        <Flex
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          flexDirection={["column", "row"]}
          color={theme.colors.white[0]}
          mx="auto"
          width="80vw"
          mb={"48px"}
        >
          <Box height="340px" width="400px" mr={["20px"]}>
            <img
              style={{ borderRadius: "6px" }}
              height="100%"
              width="100%"
              src={RecommendedImage}
            />
          </Box>
          <Flex
            mt={[32, 0]}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Typography lineHeight={0} fontSize={["32px"]}>
              Yesuf Abeba
            </Typography>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Type
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                Movie
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Country
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                Ethiopia
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Genre
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                Drama, Comedy, Romance
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Release
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                May 20, 2022
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Director
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                Solomon Bogale
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Production
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                Arkwood Movies
              </Typography>
            </Flex>{" "}
            <Flex alignItems={"center"} flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Cast
              </Typography>
              <Typography color={theme.colors.white[0]}>
                Solomon Bogale, Selam Tesfaye, Ermias Endalamaw
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default ArtistDetail;
