import { call, put, takeLatest } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import { getPackagesStart, setPackagesData, getPackagesError } from ".";
import apiRoutes from "../../app/API/routes";

function* workSubscriptionsSaga(action) {
  try {
    const response = yield call(makeAPICall, {
      route: apiRoutes.subscriptions.packages,
      method: "GET",
      query: action.payload,
    });
    console.log(response, "response");
    yield put(setPackagesData(response));
  } catch (error) {
    console.log(error);
    yield put(getPackagesError(error.message));
  }
}

function* subscriptionsWatcherSaga() {
  yield takeLatest(getPackagesStart.type, workSubscriptionsSaga);
}

export default subscriptionsWatcherSaga;
