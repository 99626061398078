import { useDispatch, useSelector } from "react-redux";
import { Flex } from "../Flex";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getMoviesStart } from "../../../../Slice/Movies";
import { getUserStart } from "../../../../Slice/User";

const Loading = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const movies = useSelector((state) => state.movies.data);

  useEffect(() => {
    dispatch(getMoviesStart());
    dispatch(getUserStart());
  }, []);

  return (
    <Flex backgroundColor={"red"}>
      <h1>Loading</h1>
    </Flex>
  );
};

export default Loading;
