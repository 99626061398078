import { useEffect, useState } from "react";
import { Box } from "../Blocks";
import { getUserFromLocalStorage } from "../../../utils/functions";
import apiRoutes from "../../API/routes";
import Iframe from "react-iframe";

const API_ROUTE = process.env.REACT_APP_BASE_URL;

const Chapa = ({ type, id }) => {
  const [chapaUrl, setChapaUrl] = useState(null);
  const user = getUserFromLocalStorage();

  let url;

  if (type === "movie") {
    url = `${API_ROUTE}/${apiRoutes.subscriptions.chapaMovie}/${id}/`;
  } else {
    url = `${API_ROUTE}/${apiRoutes.subscriptions.chapaPackage}/${id}/`;
  }

  const token = user?.access;
  useEffect(() => {
    fetch(url, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setChapaUrl(data?.checkout_url?.data?.checkout_url);
      });
  }, [url, id]);

  return (
    <Box minHeight={["100vh"]} width={["100%"]}>
      {chapaUrl && (
        <Iframe
          url={chapaUrl}
          width="640px"
          height="320px"
          id=""
          className=""
          display="block"
          position="relative"
        />
      )}
    </Box>
  );
};

export default Chapa;
