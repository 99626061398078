import { useSelector } from "react-redux";
import { theme } from "../../../../styles/theme";
import { Flex } from "../../Blocks";
import Typography from "../../Blocks/Typography";
import ArtistCard from "./ArtistCard";

const ArtistInterview = ({ title, casts }) => {
  return (
    <Flex flexDirection={"column"}>
      <Typography
        px={["2rem", "4rem"]}
        fontWeight={"800"}
        fontSize={"1.5rem"}
        color={theme.colors.white[0]}
      >
        {title}
      </Typography>
      <Flex>
        {casts?.map((cast, index) => (
          <ArtistCard key={index} cast={cast} />
        ))}
      </Flex>
    </Flex>
  );
};

export default ArtistInterview;
