import styled from "styled-components";
import { Flex } from "../Flex";
import { FilterIcon, SearchIcon } from "../../../../assets/assets.js";
import { Link, Navigate } from "react-router-dom";
import TextInput from "../TextInput";
import { theme } from "../../../../styles/theme.js";

const StyledTextInput = styled(TextInput)`
  background-color: ${theme.colors.black[2]};
  border: 1px solid ${theme.colors.black[2]};
  color: white;
`;

const handleNavigate = () => {
  Navigate({});
};

const SearchBar = ({ filter, isVisibleOnSmallScreen }) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      borderRadius={"50px"}
      backgroundColor={theme.colors.black[2]}
      display={isVisibleOnSmallScreen ? ["flex"] : ["none", "flex"]}
      px={16}
      width={"100%"}
    >
      {filter && (
        <Link
          to={"/filter"}
          style={{
            border: "none",
            padding: "6px 12px",
            backgroundColor: theme.colors.black[4],
            borderRadius: "24px",
            color: theme.colors.white[0],
            textDecoration: "none",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          {" "}
          <FilterIcon height={"12px"} />
          Filter
        </Link>
      )}

      <StyledTextInput
        height={"48px"}
        backgroundColor={theme.colors.black[2]}
        placeholder="Search for a movie"
        width={["70%"]}
        color={theme.colors.white[0]}
      />

      <SearchIcon />
    </Flex>
  );
};

export default SearchBar;
