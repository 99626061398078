import styled from "styled-components";
import { Flex, Box } from "../../Blocks";
import { RecommendedIcon, StarIcon } from "../../../../assets/assets";
import MovieCard from "../../Common/MovieCard";
import { useSelector } from "react-redux";
import AutoScrollComponent from "../Hero/TrendingContainer";

const RecommendedContainer = styled(Flex)`
  flex-direction: column;
  z-index: 999;
`;

const SectionTitle = styled.h1`
  color: #fff;
  line-height: 0px;
  font-size: 48px;
  transform: scaleY(2)
  margin: 0 64px;
`;

const TrendingContainer = styled(Box)`
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const Trending = () => {
  return (
    <RecommendedContainer
      alignItems="flex-start"
      flexDirection="column"
      mx="auto"
      height="fit-content"
      mt={["4rem"]}
    >
      <TrendingContainer>
        <Flex alignItems="center" mx={"5%"} my={2}>
          <SectionTitle>Trending</SectionTitle>
        </Flex>
        <AutoScrollComponent />
      </TrendingContainer>
    </RecommendedContainer>
  );
};

export default Trending;
