import styled from 'styled-components';
import {
  space,
  color,
  layout,
  grid,
  background,
  border,
  position,
  shadow,
  compose,
  display
} from 'styled-system';

export const Box = styled.div`
  ${compose(space, color, display, layout, grid, background, border, position, shadow)};
`;
