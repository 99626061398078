import { localStorageKeys } from "./constants";

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem(localStorageKeys.user);

  console.log(user, "user");

  if (user) {
    return JSON.parse(user);
  }

  return null;
};

export function removeUserFromLocalStorage() {
  localStorage.removeItem(localStorageKeys.user);
}
