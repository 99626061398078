import React from "react";
import styled from "styled-components";
import { typography, space, color, layout } from "styled-system";

const StyledTypography = styled.p`
  letter-spacing: 0.04em;
  white-space: wrap;

  ${typography}
  ${space}
  ${color}
  ${layout}
`;

const Typography = ({ variant, children, ...rest }) => {
  return (
    <StyledTypography variant={variant} {...rest}>
      {children}
    </StyledTypography>
  );
};

export default Typography;
