import React, { useEffect, useState } from "react";
import { Box, Flex } from "../../components/Blocks";
import Typography from "../../components/Blocks/Typography";
import Layout from "../../components/Layout";
import { theme } from "../../../styles/theme";
import SwitchButton from "../../components/Common/SwitchButton";
import RecommendedImage from "../../../assets/images/recommended.png";
import { useParams } from "react-router-dom";
import { makeAPICall } from "../../API/apiCall";
import apiRoutes from "../../API/routes";
import ArtistInterview from "../../components/Homepage/Birthday";
import Video from "../../components/Video";

function MovieDetail() {
  const [activeLanguage, setActiveLanguage] = useState("Amharic");
  const [movieData, setMovieData] = useState(null);
  const { id } = useParams();

  const languages = ["Amharic", "Afaan Oromo", "Tigrigna"];

  const handleLanguageToggle = (input) => {
    setActiveLanguage(input);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeAPICall({
          route: `${apiRoutes.movies.base}/${id}`,
          method: "GET",
        });

        if (response) {
          setMovieData(response);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  console.log(movieData, '-------------------------------------------', id, movieData);


  return (
    <Layout>
      <Flex flexDirection={["column"]} mx={["auto"]} width={["100%"]}>
        <Box
          backgroundColor={[theme.primary]}
          my={74}
        >
          <Video id={id}/>
        </Box>
        <Flex
          alignItems={["center"]}
          mx={["auto"]}
          mb={[54]}
          flexDirection={["column"]}
        >
          <Typography fontSize={["24px"]} color={theme.colors.white[0]}>
            Select Language
          </Typography>
          <Flex
            my={4}
            px={[0]}
            alignItems={["center"]}
            justifyContent={["space-between"]}
            borderRadius={[12]}
            height={[64]}
            backgroundColor={[theme.colors.black[2]]}
            flexDirection={["row"]}
          >
            {movieData?.available_language?.map((item, index) => (
              <SwitchButton
                options={languages}
                value={item?.language}
                active={activeLanguage}
                handler={handleLanguageToggle}
              />
            ))}
          </Flex>
        </Flex>
        <Flex
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          flexDirection={["column", "column", "column", "row"]}
          color={theme.colors.white[0]}
          mx="auto"
          width="80vw"
          mb={"48px"}
        >
          <Box height="340px" width="400px" mr={["20px"]}>
            <img
              style={{}}
              height="100%"
              width="100%"
              src={movieData?.poster?.high_quality}
            />
          </Box>
          <Flex
            mt={[32, 0]}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={["32px"]}>{movieData?.title}</Typography>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Type
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                {movieData?.type}
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Country
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                {movieData?.country}
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Genre
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                {movieData?.genre.map((genre, index) => (
                  <span key={index}>{genre?.name}</span>
                ))}
              </Typography>
            </Flex>
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Release
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                {movieData?.release_date}
              </Typography>
            </Flex>
            {/* <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Director
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                Solomon Bogale
              </Typography>
            </Flex> */}
            <Flex flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Production
              </Typography>
              <Typography lineHeight={"0"} color={theme.colors.white[0]}>
                {movieData?.production.map((production, index) => (
                  <span key={index}>{production?.name}</span>
                ))}
              </Typography>
            </Flex>{" "}
            {/* <Flex alignItems={"center"} flexDirection={"row"}>
              <Typography
                lineHeight={"0"}
                color={theme.colors.white[2]}
                mr={"16px"}
                minWidth={"120px"}
              >
                Cast
              </Typography>
              <Typography color={theme.colors.white[0]}>
                Solomon Bogale, Selam Tesfaye, Ermias Endalamaw
              </Typography>
            </Flex> */}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        bg={theme.colors.secondary[3]}
        overflow={"scroll"}
        maxWidth={["100vw"]}
        py={4}
      >
        <ArtistInterview casts={movieData?.cast} title={"Artists"} />
      </Flex>
    </Layout>
  );
}

export default MovieDetail;
