import styled from "styled-components";
import {
  DesktopIcon,
  MenuIcon,
  SubscriptionIcon,
} from "../../../assets/assets";
import { theme } from "../../../styles/theme";
import { Box, Button, Flex, SearchBar } from "../Blocks";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import Typography from "../Blocks/Typography";
import { useDispatch, useSelector } from "react-redux";
import { removeUserFromLocalStorage } from "../../../utils/functions";
import { clearUserData } from "../../../Slice/Auth";
import ArkwoodLogo from "../../../assets/images/Arkwood_Logo.png";

const SubscriptionButton = styled(Flex)`
  cursor: pointer;
`;

const Container = styled(Flex)`
  background-image: linear-gradient(
    to bottom,
    rgba(12, 20, 21, 0.7) 10%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 12;
  z-index: 99;
  width: 100%;
`;

const Profile = styled(Box)`
  cursor: pointer;
`;

const NavBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const handleSubscriptionsClick = useCallback(() => {
    navigate("/subscriptions");
  }, []);

  const handleProfileHover = () => {
    setIsModalOpen(true);
  };

  const handleProfileLeave = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = () => {
    removeUserFromLocalStorage();
    dispatch(clearUserData());
    navigate("/login");
  };

  const Modal = styled(Flex)`
    position: absolute;
    top: 100%;
    right: 2%;
    background: #f4f4f4;
    padding: 24px 16px;
    z-index: 999;
    border-radius: 4px;
  `;

  return (
    <Container
      alignItems="center"
      height={12}
      justifyContent="space-between"
      mx="auto"
      py={"40px"}
    >
      <Link style={{ padding: 0, margin: 0 }} to="/movies">
        <img src={ArkwoodLogo} height="40px" />
      </Link>

      <Flex width={["40%"]}>
        <SearchBar isVisibleOnSmallScreen={false} />
      </Flex>
      <Flex height={[40]} alignItems={["center"]}>
        <SubscriptionButton
          backgroundColor={theme.colors.primary[0]}
          borderRadius={"48px"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"48px"}
          width={"48px"}
          onClick={handleSubscriptionsClick}
        >
          <SubscriptionIcon height={18} width={18} />
        </SubscriptionButton>
        <Profile
          onMouseEnter={handleProfileHover}
          backgroundColor={theme.colors.primary[0]}
          borderRadius={"48px"}
          height={"48px"}
          width={"48px"}
          mx={[2]}
        >
          <img
            src={user?.profile_picture}
            height="100%"
            width="100%"
            style={{ borderRadius: "50%" }}
          />
        </Profile>
      </Flex>
      {isModalOpen && (
        <Modal
          onMouseLeave={handleProfileLeave}
          width={["60%", "40%", "40%", "20%"]}
          flexDirection={["column"]}
        >
          <Link style={{ textDecoration: "none" }} to={"/me"}>
            <Flex>
              <Box
                backgroundColor={theme.colors.black[0]}
                borderRadius={"48px"}
                height={"48px"}
                width={"48px"}
                mx={[2]}
              >
                <img
                  src={user?.profile_picture}
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <Typography>{user?.username}</Typography>
            </Flex>
          </Link>
          <Flex mt={[4]}>
            <Button onClick={handleSignOut}>Signout</Button>
          </Flex>
        </Modal>
      )}
    </Container>
  );
};

export default NavBar;
