import { Box, Flex } from "../Blocks";
import Typography from "../Blocks/Typography";

const Footer = () => {
  return (
    <Flex
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9)), url(https://images.unsplash.com/photo-1573404353091-bd68e3010d73?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="100%"
      py={32}
    >
      <Flex
        justifyContent={["flex-end"]}
        flexDirection={["column"]}
        mx="auto"
        width={["90%"]}
      >
        <Flex
          flexDirection={["column", "column", "row", "row"]}
          justifyContent={["space-between"]}
          alignItems={["center"]}
          flexWrap={["wrap"]}
        >
          <Typography
            textAlign={["right"]}
            lineHeight={["0"]}
            fontSize={["40px"]}
            fontWeight={["800"]}
            color={"#f18316"}
          >
            Arkwood
          </Typography>
        </Flex>
        <Box borderBottom={"1px solid #f18316"} />
        <Flex flexDirection={["column"]} mt={2}>
          <Typography
            textAlign={["center"]}
            fontSize={["12px"]}
            color={"#888888"}
          >
            Arkwood is top of African streaming website, where to watch movies
            online with small subscription fee. With a big database and great
            features, we're confident Arkwood is the best online website in the
            space that you can't simply miss!
          </Typography>
          <Typography
            textAlign={["center"]}
            fontSize={["12px"]}
            color={"#f18316"}
          >
            This site does not store any files on our server, we only linked to
            the media which is hosted on 3rd party services.
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
