import axios from "axios";
import { localStorageKeys } from "../../utils/constants";
import apiRoutes from "./routes";
import { getUserFromLocalStorage } from "../../utils/functions";

const API_ROUTE = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create();

// Request interceptor
axiosInstance.interceptors.request.use(async (config) => {
  const user = await getUserFromLocalStorage();
  const token = user?.access;

  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }

  return config;
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    const user = await getUserFromLocalStorage();

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (user) {
        try {
          // Refresh the token
          const refreshToken = user.refresh;
          url = `${API_ROUTE}/${apiRoutes.user.refresh}`;
          const response = await axios.post(url, {
            refresh: refreshToken,
          });
          const responseData = JSON.parse(response.data);
          const newToken = responseData.data;

          // Update the token in local storage
          localStorage.setItem(localStorageKeys.user, newToken);

          // Update the Authorization header with the new token
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `JWT ${newToken.access}`;

          // Retry the original request with the updated token
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          // Handle refresh token error, e.g., redirect to login page
        }
      }
    }

    return Promise.reject(error);
  }
);

export const makeAPICall = async ({ route, method, query, body }) => {
  try {
    const fullURL = `${API_ROUTE}/${route}`;
    const response = await axiosInstance({
      method,
      params: query,
      data: body,
      url: fullURL,
      responseType: "json",
    });

    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    // console.log(error);
    throw new Error(error);
  }
};
