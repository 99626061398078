import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../Slice/Auth/index";
import signUpReducer from "../Slice/Signup/index";
import moviesReducer from "../Slice/Movies/index";
import userReducer from "../Slice/User/index";
import castsReducer from "../Slice/Cast/index";
import trendingReducer from "../Slice/Trending/index";
import subscriptionReducer from "../Slice/Subscription/index";

const rootReducer = combineReducers({
  auth: authReducer,
  signUp: signUpReducer,
  movies: moviesReducer,
  user: userReducer,
  casts: castsReducer,
  trending: trendingReducer,
  subscription: subscriptionReducer,
});

export default rootReducer;
