import * as yup from "yup";

export const SignupSchema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone_number: yup.number().required(),
  date_of_birth: yup.string().required(),
  country: yup.string().required(),
  password: yup.string().required(),
  re_password: yup.string().required(),
});
