import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { watchRegisterUser } from "../Slice/Signup/saga";
import authWatcherSaga from "../Slice/Auth/saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import moviesWatcherSaga from "../Slice/Movies/saga";
import userWatcherSaga from "../Slice/User/Saga";
import castsWatcherSaga from "../Slice/Cast/saga";
import trendingMoviesWatcherSaga from "../Slice/Trending/saga";
import subscriptionsWatcherSaga from "../Slice/Subscription/saga";

const saga = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [saga],
});

saga.run(authWatcherSaga);
saga.run(watchRegisterUser);
saga.run(moviesWatcherSaga);
saga.run(userWatcherSaga);
saga.run(castsWatcherSaga);
saga.run(trendingMoviesWatcherSaga);
saga.run(subscriptionsWatcherSaga);

const persistor = persistStore(store);

export { store, persistor };
