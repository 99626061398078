import { useDispatch } from "react-redux";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { useEffect } from "react";
import { getMoviesStart } from "../../../Slice/Movies";
import { getUserStart } from "../../../Slice/User";

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMoviesStart());
    dispatch(getUserStart());
  }, []);

  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
