import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { Flex } from "../Blocks";
import Typography from "../Blocks/Typography";
// import Modal from "react-modal";
import React from "react";
import Stripe from "../Checkout/Stripe";
import Chapa from "../Checkout/Chapa";
import SwitchButton from "./SwitchButton";
import { CloseIcon } from "../../../assets/assets";
import Modal from "../Blocks/Modal";

const LoginButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  color: white;
  font-size: ${theme.fontSizes[5]};
`;

const ImageContainer = styled(Flex)`
  width: 124px;
  height: 124px;
  border-radius: 64px;
  border: 3px solid ${theme.colors.white[0]};
`;
const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: "10%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    width: "70%",
    backgroundColor: "#3a6073",
    borderColor: "transparent",
  },
  scrollableContent: {
    /* styles */
    maxHeight: "500px", // Adjust the maximum height as needed
    overflowY: "auto",
  },
};

const SubscriptionCard = ({ detail }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [activePaymentMethod, setActivePaymentMethod] = React.useState("ETB");

  const paymentMethods = ["ETB", "USD"];

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleLanguageToggle = (input) => {
    setActivePaymentMethod(input);
  };

  const renderPaymentMethod = () => {
    if (activePaymentMethod === "USD") {
      return <Stripe type={"package"} id={detail?.id} />;
    } else {
      return <Chapa type={"package"} id={detail?.id} />;
    }
  };

  return (
    <>
      <Flex
        borderRadius={[4]}
        p={[4]}
        backgroundColor={[theme.colors.accent[4]]}
        width={["100%", "100%", "100%", "30%"]}
        mx={4}
        mb={[4, 4, 4, 0]}
        flexDirection={["column"]}
        alignItems={"center"}
      >
        <ImageContainer>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
          >
            <img
              src={detail?.packages_image}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
              alt="Trending Image"
            />
          </div>
        </ImageContainer>
        <Flex
          alignItems={["center"]}
          flexDirection={["row"]}
          justifyContent={["center"]}
        >
          <Typography
            fontWeight={"800"}
            color={theme.colors.white[1]}
            fontSize={["32px"]}
            mx={[3]}
          >
            {detail?.name}
          </Typography>
        </Flex>
        <Flex alignItems={["center"]} flexDirection={["column"]}>
          {detail?.price?.map((item, index) => {
            return (
              <Typography
                key={index}
                fontWeight={"300"}
                color={theme.colors.white[1]}
                lineHeight={[0]}
                fontSize={["24px"]}
                mx={[3]}
                fontStyle={["italic"]}
              >
                {item?.price_currency + " " + item?.price}
              </Typography>
            );
          })}
        </Flex>
        <LoginButton
          // onClick={openModal}
          onClick={openModal}
        >
          Purchase
        </LoginButton>
      </Flex>
      <Modal onClose={closeModal} open={modalIsOpen}>
        <Flex
          alignItems={["center"]}
          justifyContent={["space-between"]}
          flexDirection={["column"]}
          width={["100%"]}
          height={["fit"]}
        >
          <CloseBtn onClick={closeModal}>
            <CloseIcon height={"24px"} width={"24px"} />
          </CloseBtn>
          <Flex
            my={4}
            px={[0]}
            alignItems={["center"]}
            justifyContent={["space-between"]}
            borderRadius={[12]}
            height={[64]}
            backgroundColor={[theme.colors.black[2]]}
            flexDirection={["row"]}
          >
            {paymentMethods?.map((item, index) => (
              <SwitchButton
                options={paymentMethods}
                value={item}
                active={activePaymentMethod}
                handler={handleLanguageToggle}
              />
            ))}
          </Flex>
          <Flex backgroundColor={theme.colors.white[0]} width={"100%"}>
            {renderPaymentMethod()}
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default SubscriptionCard;
