import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, SearchBar } from "../Blocks";
import Layout from "../Layout";
import ArtistInterview from "./Birthday";
import HeroSection from "./Hero";
import Recommended from "./Recommended";
import React, { useEffect, useState } from "react";
import { getMoviesStart } from "../../../Slice/Movies";
import { getUserStart } from "../../../Slice/User";
import { getUserFromLocalStorage } from "../../../utils/functions";
import Loading from "../Blocks/Loading";
import { getCastsStart } from "../../../Slice/Cast";
import { theme } from "../../../styles/theme";
import { getPackagesStart } from "../../../Slice/Subscription";
import Trending from "./Trending";
import SwitchButton from "../Common/SwitchButton";
import MovieCard from "../Common/MovieCard";
import NoMovieFound from "../Blocks/NoMovieFound/NoMovieFound";
import styled from "styled-components";
import { CloseIcon } from "../../../assets/assets";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";
import TextInput from "../Blocks/TextInput";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const style = {};

const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const FilterBtn = styled(Flex)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 54px;
  width 54px;
  padding: 18px;
  border-radius: 24px;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary[0]};
  z-index: 9999;
  cursor: pointer;
`;

const MobileTab = styled(Flex)`
  gap: 1rem;
  padding:
  color: ${theme.colors.accent[3]};
  font-weight: 500;
`;

const HomepageComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.movies.isLoading);
  const [showMovieMenu, setShowMovieMenu] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isAdultContent, setIsAdultContent] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [cast, setCast] = useState(null);
  const [genre, setGenre] = useState(null);
  const [production, setProduction] = useState(null);
  const [award, setAward] = useState(null);

  const casts = useSelector((state) => state.casts.data);

  const [activeMovieType, setActiveMovieType] = React.useState("All");

  const movieTypes = ["All", "New", "Trending", "Recommended"];

  const movies = useSelector((state) => state.movies.data);
  const trendingMovies = useSelector((state) => state.trending.data);

  const handleMoviesTypeChange = (input) => {
    setShowMovieMenu(false);
    setActiveMovieType(input);
  };

  const handleFilterApplied = (input) => {
    setShowFilterModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = getUserFromLocalStorage();
      if (user) {
        // Fetch user data first
        dispatch(getUserStart());
        dispatch(getCastsStart());
        dispatch(getMoviesStart());
        dispatch(getPackagesStart());
      }
    };

    fetchData();
  }, [dispatch]);

  const renderMovies = () => {
    let moviesRendered;
    if (activeMovieType === "All") {
      moviesRendered = movies;
    } else if (activeMovieType === "Trending") {
      moviesRendered = trendingMovies;
    } else if (activeMovieType === "Recommended") {
      moviesRendered = movies;
    }

    if (!moviesRendered?.results?.length > 0) {
      return <NoMovieFound />;
    }

    return moviesRendered?.results?.map((movie, index) => (
      <MovieCard key={index} movie={movie} />
    ));
  };

  if (isLoading) {
    return <Loading />;
  }

  //handle FORMS

  const handleAdultCheckbox = (event) => {
    setIsAdultContent(event.target.checked);
  };

  const handleIsFreeCheckbox = (event) => {
    setIsFree(event.target.checked);
  };

  const handleApplyFilters = () => {
    dispatch(
      getMoviesStart({
        cast: cast,
        genre: genre,
        production: production,
        award: award,
        is_free: isFree,
        adult_content: isAdultContent,
      })
    );
    setShowFilterModal(false);
  };

  return (
    <Layout>
      <HeroSection />
      <Trending />

      <FilterBtn onClick={() => setShowFilterModal(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="20"
          viewBox="0 0 512 512"
        >
          <path
            fill="#ffffff"
            d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
          />
        </svg>
      </FilterBtn>
      <Modal
        onClose={() => setShowFilterModal(false)}
        open={showFilterModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "6px",
            pt: 2,
            px: 4,
            pb: 3,
            width: "60%", // Default width for larger screens
            // Media query for screens below 960px
            "@media (max-width: 960px)": {
              width: "70%",
            },
            // Media query for screens below 600px
            "@media (max-width: 700px)": {
              width: "95%",
            },
          }}
        >
          <CloseBtn onClick={() => handleFilterApplied(false)}>
            <CloseIcon height={"24px"} width={"24px"} />
          </CloseBtn>
          <FormGroup
            sx={{
              marginTop: "3rem",
              padding: "3rem",
              color: theme.colors.black[3],
            }}
          >
            <Flex
              flexDirection={[
                "column-reverse",
                "column-reverse",
                "column-reverse",
                "row",
              ]}
              justifyContent={["space-between"]}
              alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="datePicker"
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    sx={{
                      color: theme.colors.black[3],
                      "&.Mui-checked": {
                        color: theme.colors.primary[0],
                      },
                    }}
                    checked={isAdultContent}
                    onChange={handleAdultCheckbox}
                  />
                }
                label="Adult content"
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    sx={{
                      color: theme.colors.black[3],
                      "&.Mui-checked": {
                        color: theme.colors.primary[0],
                      },
                    }}
                    checked={isFree}
                    onChange={handleIsFreeCheckbox}
                  />
                }
                label="Free"
              />
            </Flex>

            <TextInput
              placeholder="cast"
              p={2}
              name="cast"
              my={["10px"]}
              width={["100%"]}
              value={cast}
              onChange={(e) => setCast(e.target.value)}
            />
            <TextInput
              placeholder="Genre"
              p={2}
              name="genre"
              my={["10px"]}
              width={["100%"]}
              value={genre}
              onChange={(e) => setGenre(e.target.value)}
            />
            <TextInput
              placeholder="Production"
              p={2}
              name="production"
              my={["10px"]}
              width={["100%"]}
              value={production}
              onChange={(e) => setProduction(e.target.value)}
            />
            <TextInput
              placeholder="Award"
              p={2}
              name="award"
              my={["10px"]}
              width={["100%"]}
              value={award}
              onChange={(e) => setAward(e.target.value)}
            />
            {/* </form> */}
            <Button onClick={handleApplyFilters} my={[3]}>
              Apply Filters
            </Button>
          </FormGroup>
        </Box>
      </Modal>
      <Flex
        backgroundColor={[theme.colors.accent[4]]}
        flexDirection={["column"]}
        my={[5]}
      >
        <Flex
          my={4}
          px={[0]}
          alignItems={["center"]}
          justifyContent={["space-between"]}
          borderRadius={[24]}
          height={[48]}
          width={["90%"]}
          mx={["auto"]}
          backgroundColor={["transparent"]}
          flexDirection={["row"]}
        >
          <Flex
            color={[theme.colors.white[0]]}
            display={["none", "none", "flex"]}
          >
            {movieTypes?.map((item, index) => (
              <SwitchButton
                key={index}
                options={movieTypes}
                value={item}
                active={activeMovieType}
                handler={handleMoviesTypeChange}
              />
            ))}
          </Flex>
          <Flex
            display={["flex", "flex", "none"]}
            alignItems={["center"]}
            justifyContent={["center"]}
            height={[40]}
            width={[40]}
            backgroundColor={theme.colors.primary[0]}
            borderRadius={[20]}
            onClick={() => setShowMovieMenu(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="14"
              width="12.5"
              viewBox="0 0 448 512"
            >
              <path
                fill="#ffffff"
                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
              />
            </svg>
          </Flex>
          <Flex width={["90%", "90%", "30%", "30%"]}>
            <SearchBar isVisibleOnSmallScreen={true} />
          </Flex>

          <Modal
            onClose={() => setShowMovieMenu(false)}
            open={showMovieMenu}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                borderRadius: "6px",
                pt: 2,
                px: 4,
                pb: 3,
                width: "60%", // Default width for larger screens
                // Media query for screens below 960px
                "@media (max-width: 960px)": {
                  width: "70%",
                },
                // Media query for screens below 600px
                "@media (max-width: 700px)": {
                  width: "95%",
                },
              }}
            >
              <CloseBtn onClick={() => setShowMovieMenu(false)}>
                <CloseIcon height={"24px"} width={"24px"} />
              </CloseBtn>
              <MobileTab py={["10%"]} flexDirection={["column"]}>
                {movieTypes?.map((item, index) => (
                  <SwitchButton
                    key={index}
                    options={movieTypes}
                    value={item}
                    active={activeMovieType}
                    handler={handleMoviesTypeChange}
                  />
                ))}
              </MobileTab>
            </Box>
          </Modal>
        </Flex>
        <Flex justifyContent="flex-start" flexWrap="wrap" width="90%" mx="auto">
          {renderMovies()}
        </Flex>
      </Flex>
      <Flex overflow={"scroll"} maxWidth={["100vw"]} py={4}>
        <ArtistInterview casts={casts?.results} title={"Artists"} />
      </Flex>
      <Flex overflow={"scroll"} maxWidth={["100vw"]}></Flex>
    </Layout>
  );
};

export default HomepageComponent;
