import React, { useEffect, useRef, useState } from "react";
import { Flex } from "../../Blocks";
import Trending from "./Trending";
import { useSelector } from "react-redux";

const AutoScrollComponent = ({ children }) => {
  const containerRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);

  const trendingMovies = useSelector((state) => state.trending.data);

  useEffect(() => {
    const container = containerRef.current;
    let scrollInterval;

    const startScrolling = () => {
      scrollInterval = setInterval(() => {
        setScrolling(true);
        container.scrollLeft += 600;
      }, 2500);
    };

    const stopScrolling = () => {
      clearInterval(scrollInterval);
      setScrolling(false);
    };

    container.addEventListener("mouseenter", stopScrolling);
    container.addEventListener("mouseleave", startScrolling);

    startScrolling();

    return () => {
      container.removeEventListener("mouseenter", stopScrolling);
      container.removeEventListener("mouseleave", startScrolling);
      stopScrolling();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`scroll-container ${scrolling ? "scrolling" : ""}`}
    >
      <Flex>
        {trendingMovies?.results?.map((Movie, index) => (
          <Trending index={index} Movie={Movie} />
        ))}
      </Flex>
      <style jsx>{`
        .scroll-container {
          overflow-x: auto;
          white-space: nowrap;
          transition: scroll-left 0.2s ease-in-out;
        }

        .scroll-container.scrolling {
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;
        }
      `}</style>
    </div>
  );
};

export default AutoScrollComponent;
