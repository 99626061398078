import styled from "styled-components";
import { DesktopIcon } from "../../../assets/assets";
import { Flex } from "../../components/Blocks/Flex";
import TextInput from "../../components/Blocks/TextInput";
import Typography from "../../components/Blocks/Typography";
import { theme } from "../../../styles/theme";
import { LoginSchema } from "../../../validation/UserLogin";
import { login } from "../../../Slice/Auth";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { getUserFromLocalStorage } from "../../../utils/functions";
import { getMoviesStart } from "../../../Slice/Movies";
import { getUserStart } from "../../../Slice/User";

const LoginButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${theme.fontSizes[5]};
`;

const StyledFlex = styled(Flex)`
  background: linear-gradient(to right, #16222a, #3a6073);
  border-radius: 8px;
`;

const Login = () => {
  const [invalidForm, setIsInvalidForm] = useState(undefined);

  const userLoggedIn = useSelector((state) => state.auth.success);
  const userLogInError = useSelector((state) => state.auth.error);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const loginUser = async (e) => {
    e.preventDefault();

    console.log(invalidForm, "invalidForm");

    let userData = {
      email: formData.email,
      password: formData.password,
    };
    const isValid = await LoginSchema.isValid(userData);
    if (isValid) {
      dispatch(login(userData));
      setIsInvalidForm(false);
    } else {
      setIsInvalidForm(true);
    }
  };

  useEffect(() => {
    if (userLoggedIn) {
      navigate("/movies");
    } else {
      navigate("/login");
    }
  }, [userLoggedIn]);

  console.log(userLogInError, "userLogInError");

  return (
    <Flex
      height={["fit", "100vh"]}
      alignItems={"center"}
      justifyContent={"center"}
      backgroundColor={"#12161F"}
    >
      <StyledFlex
        py={4}
        px={[4, 6]}
        alignItems={["center"]}
        justifyContent={["space-between"]}
        flexDirection={["column"]}
        width={["98%", "96%", "96%", "70%"]}
      >
        <Flex
          alignItems={["center"]}
          justifyContent={["space-between"]}
          flexDirection={["column", "column", "row", "row"]}
          width={["100%"]}
        >
          <Flex
            alignItems={["center"]}
            display={["block", "block", "block"]}
            flexDirection={["column"]}
            width={["100%", "100%", "65%"]}
          >
            <Flex flexDirection={"column"}>
              <Typography
                color={theme.colors.white[0]}
                fontWeight={["800"]}
                fontSize={["24px"]}
              >
                Home of African Entertainment.
              </Typography>
              <Typography
                mt={"-20px"}
                color={theme.colors.white[0]}
                fontWeight={["800"]}
                fontSize={["24px"]}
              >
                {" "}
                African Entertainment.
              </Typography>
            </Flex>
            <Flex alignItems={["center"]} flexDirection={["row"]} my={["24px"]}>
              <DesktopIcon height={["56px"]} width={["56px"]} />
              <Flex flexDirection={["column"]} mx={["32px"]}>
                <Typography
                  lineHeight={["16px"]}
                  color={theme.colors.white[0]}
                  fontWeight={["700"]}
                  fontSize={["16px"]}
                >
                  Home of African Entertainment
                </Typography>
                <Typography
                  lineHeight={["14px"]}
                  mt={["-12px"]}
                  color={theme.colors.white[0]}
                  fontWeight={["200"]}
                  fontSize={["14px"]}
                >
                  Home of African Entertainment
                </Typography>
              </Flex>
            </Flex>
            <Flex alignItems={["center"]} flexDirection={["row"]} my={["24px"]}>
              <DesktopIcon height={["56px"]} width={["56px"]} />
              <Flex flexDirection={["column"]} mx={["32px"]}>
                <Typography
                  lineHeight={["16px"]}
                  color={theme.colors.white[0]}
                  fontWeight={["700"]}
                  fontSize={["16px"]}
                >
                  Home of African Entertainment
                </Typography>
                <Typography
                  lineHeight={["14px"]}
                  mt={["-12px"]}
                  color={theme.colors.white[0]}
                  fontWeight={["200"]}
                  fontSize={["14px"]}
                >
                  Home of African Entertainment
                </Typography>
              </Flex>
            </Flex>
            <Flex alignItems={["center"]} flexDirection={["row"]} my={["24px"]}>
              <DesktopIcon height={["56px"]} width={["56px"]} />
              <Flex flexDirection={["column"]} mx={["32px"]}>
                <Typography
                  lineHeight={["16px"]}
                  color={theme.colors.white[0]}
                  fontWeight={["700"]}
                  fontSize={["16px"]}
                >
                  Home of African Entertainment
                </Typography>
                <Typography
                  lineHeight={["14px"]}
                  mt={["-12px"]}
                  color={theme.colors.white[0]}
                  fontWeight={["200"]}
                  fontSize={["14px"]}
                >
                  Home of African Entertainment
                </Typography>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            display={["block", "block", "block"]}
            flexDirection={["column"]}
            width={["100%", "100%", "50%"]}
          >
            <Typography
              color={theme.colors.white[0]}
              fontWeight={["600"]}
              fontSize={["18px"]}
            >
              Welcome back
            </Typography>
            {invalidForm === true && (
              <Flex
                backgroundColor={theme.colors.primary[3]}
                border={`1px solid ${theme.colors.primary[2]}`}
                alignItems={"center"}
                justifyContent={"center"}
                height={["64px"]}
                p={[2]}
                borderRadius={["6px"]}
              >
                <Typography color={theme.colors.white[0]}>
                  Please check your Email and Password
                </Typography>
              </Flex>
            )}
            <form onSubmit={loginUser}>
              <TextInput
                placeholder="Email"
                p={2}
                name="email"
                my={["10px"]}
                width={["100%"]}
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextInput
                placeholder="Password"
                p={2}
                name="password"
                my={["10px"]}
                mb={["26px"]}
                width={["100%"]}
                value={formData.password}
                onChange={handleInputChange}
              />
              <LoginButton type="submit" width={"100%"}>
                Login
              </LoginButton>
            </form>
            <Link
              to="/signUp"
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textDecorationColor: theme.colors.primary[0],
              }}
            >
              <Typography textAlign={"center"} color={theme.colors.primary[0]}>
                Don't have Account
              </Typography>
            </Link>
          </Flex>
        </Flex>
        <Flex alignItems={["center"]} flexDirection={["column"]} mt={["96px"]}>
          <Typography
            lineHeight={["14px"]}
            mt={["-12px"]}
            color={theme.colors.white[0]}
            fontWeight={["200"]}
            fontSize={["14px"]}
          >
            By signing up, you agree to the Terms & Conditions and Privacy
            Policy
          </Typography>
        </Flex>
      </StyledFlex>
    </Flex>
  );
};

export default Login;
