import styled from "styled-components";
import { Flex } from "../../components/Blocks/Flex";
import Typography from "../../components/Blocks/Typography";
import { theme } from "../../../styles/theme";
import { useNavigate } from "react-router-dom";

const MainContainer = styled(Flex)`
  background: linear-gradient(to right, #16222a, #3a6073);
`;

const LoginButton = styled.button`
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 4rem;
  font-size: ${theme.fontSizes[5]};
`;

const AgreementTerm = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainContainer
      height={["100%"]}
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      px={"5%"}
      py={"5%"}
      color={theme.colors.white[0]}
    >
      <LoginButton onClick={handleBack}>Go Back</LoginButton>

      <Typography fontSize={["32px"]} fontWeight={["800"]}>
        Arkwood’sTerms of Use
      </Typography>
      <Typography>
        Arkwood provides a personalized subscription and per-movie service that
        allows members to access entertainment content (“Arkwood’s content”)
        over the Internet on certain Internet-connected Mobiles, TVs, computers
        and other devices. These Terms of Use govern your use of our service. As
        used in these Terms of Use, "Arkwood’s service", "our service" or "the
        service" means the personalized service provided by Arkwood for
        discovering and accessing entertainment content, including all features
        and functionalities, recommendations and reviews, our websites, and user
        interfaces, as well as all content and software associated with our
        service.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        1. Membership and Usage
      </Typography>
      <Typography>
        1.1. Your Arkwood membership and per-movie payment will continue until
        terminated. To use the service you must have Internet access and an
        arkwood ready device, and provide us with one or more Payment Methods.
        “Payment Method” means a current, valid, accepted method of payment, as
        may be updated from time to time, and which may include payment through
        your account with a third party. Unless you cancel your membership
        before your billing date, you authorize us to charge the membership fee
        for the billing cycle to your Payment Method (see "Cancellation" below).
      </Typography>
      <Typography>
        1.2. We may offer a number of Subscription plans, including memberships
        offered by third parties in conjunction with the provision of their own
        products and services. We are not responsible for the products and
        services provided by such third parties. Some membership plans may have
        differing conditions and limitations, which will be disclosed at your
        sign-up or in other communications made available to you. You can find
        specific details regarding your Arkwood membership by visiting the
        official website and clicking on the "Account" link.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        2. Billing and Cancellation
      </Typography>
      <Typography>
        2.1. Payment Methods. To use the service you might need to provide one
        or more Payment Methods. You authorize us to charge any Payment Method
        associated with your account in case your primary Payment Method is
        declined or no longer available to us for payment of your subscription
        fee. You remain responsible for any uncollected amounts. If a payment is
        not successfully settled, due to expiration, insufficient funds, or
        otherwise, and you do not cancel your account, we may suspend your
        access to the service until we have successfully charged a valid Payment
        Method. For some Payment Methods, the issuer may charge you certain
        fees, such as foreign transaction fees or other fees relating to the
        processing of your Payment Method. Local tax charges may vary depending
        on the Payment Method used. Check with your Payment Method service
        provider for details.
      </Typography>
      <Typography>
        2.2. Cancellation. You can cancel your membership at any time, and you
        will continue to have access to the service through the end of your
        billing period. To the extent permitted by the applicable law, payments
        are non-refundable and we do not provide refunds or credits for any
        partial membership periods or unused Netflix content.If you cancel your
        membership, your account will automatically close at the end of your
        current billing period. If you signed up for the service using your
        account with a third party as a Payment Method and wish to cancel your
        membership, you may need to do so through such third party, for example
        by visiting your account with the applicable third party and turning off
        auto-renew, or unsubscribing from the Netflix service through that third
        party.
      </Typography>
      <Typography>
        2.3. Changes to the Price and Subscription Plans. We may change our
        subscription plans and the price of our service from time to time;
        however, any price changes or changes to your subscription plans will
        apply no earlier than 30 days following notice to you.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        3. Arkwood’s Service
      </Typography>
      <Typography>
        3.1. You must be at least 18 years of age, or the age of majority in
        your province, territory or country, to become a member of the service.
        Minors may only use the service under the supervision of an adult.
      </Typography>
      <Typography>
        3.2. The Arkwood service and any content accessed through the service
        are for your personal and non-commercial use only and may not be shared
        with individuals beyond your household. During your membership we grant
        you a limited, non-exclusive, non-transferable right to access the
        service and content. Except for the foregoing, no right, title or
        interest shall be transferred to you. You agree not to use the service
        for public performances.
      </Typography>
      <Typography>
        3.3. You may access the content primarily within the country in which
        you have established your account.
      </Typography>
      <Typography>
        3.4. The service, including the content library, is regularly updated.
        In addition, we continually test various aspects of our service,
        including our websites, user interfaces, promotional features and
        availability of Netflix content.
      </Typography>
      <Typography>
        3.5. You agree to use the service, including all features and
        functionalities associated therewith, in accordance with all applicable
        laws, rules and regulations, or other restrictions on use of the service
        or content therein. You agree not to archive, reproduce, distribute,
        modify, display, perform, publish, license, create derivative works
        from, offer for sale, or use (except as explicitly authorized in these
        Terms of Use) content and information contained on or obtained from or
        through the Arkwood service. You also agree not to: circumvent, remove,
        alter, deactivate, degrade or thwart any of the content protections in
        the Arkwood service; use any robot, spider, scraper or other automated
        means to access the Arkwood’s service; decompile, reverse engineer or
        disassemble any software or other products or processes accessible
        through the Arkwoods service; insert any code or product or manipulate
        the content of the Arkwoods service in any way; or use any data mining,
        data gathering or extraction method. In addition, you agree not to
        upload, post, e-mail or otherwise send or transmit any material designed
        to interrupt, destroy or limit the functionality of any computer
        software or hardware or telecommunications equipment associated with the
        Arkwood service, including any software viruses or any other computer
        code, files or programs. We may terminate or restrict your use of our
        service if you violate these Terms of Use or are engaged in illegal or
        fraudulent use of the service.
      </Typography>
      <Typography>
        3.6. The quality of the display of the Arkwood content may vary from
        device to device, and may be affected by a variety of factors, such as
        your location, the bandwidth available through and/or speed of your
        Internet connection. HD, Ultra HD and HDR availability is subject to
        your Internet service and device capabilities. Not all content is
        available in all formats, such as HD, Ultra HD or HDR. Default playback
        settings on cellular networks exclude HD, Ultra HD and HDR content. The
        minimum connection speed for SD quality is 1.0 Mbps; however, we
        recommend a faster connection for improved video quality. A download
        speed of at least 3.0 Mbps per stream is recommended to receive HD
        content (defined as a resolution of 720p or higher). A download speed of
        at least 15.0 Mbps per stream is recommended to receive Ultra HD
        (defined as a resolution of 4K or higher). You are responsible for all
        Internet access charges. Please check with your Internet provider for
        information on possible Internet data usage charges. The time it takes
        to begin watching Arkwood content will vary based on a number of
        factors, including your location, available bandwidth at the time, and
        the content you have selected.
      </Typography>
      <Typography>
        3.7. Arkwood software is developed by, or for, Arkwood and may solely be
        used for authorized streaming and to access Arkwood’s content through
        ready devices. This software may vary by device and medium, and
        functionalities and features may also differ between devices. You
        acknowledge that the use of the service may require third party software
        that is subject to third party licenses. You agree that you may
        automatically receive updated versions of the Arkwood’s software and
        related third-party software.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        4. Passwords and Account Access
      </Typography>
      <Typography>
        The member who created the account and whose Payment Method is charged
        (the "Account Owner") is responsible for any activity that occurs
        through the Arkwood account. To maintain control over the account and to
        prevent anyone from accessing the account (which would include
        information on viewing history for the account), the Account Owner
        should maintain control over the Arkwood ready devices that are used to
        access the service and not reveal the two-step verification codes ,
        passcodes, OTP’s (one-time password) which is sent directly to the
        owners provided phone number or details of the Payment Method associated
        with the account to anyone. You are responsible for updating and
        maintaining the accuracy of the information you provide to us relating
        to your account. We can terminate your account or place your account on
        hold in order to protect you, Arkwood or our partners from identity
        theft or other fraudulent activity.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        5. Warranties and Limitations on Liability
      </Typography>
      <Typography>
        The Arkwood service is provided "as is" and without warranty or
        condition. In particular, our service may not be uninterrupted or
        error-free. You waive all special, indirect and consequential damages
        against us. These terms will not limit any non-waivable warranties or
        consumer protection rights that you may be entitled to under the
        mandatory laws of your country of residence.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        6. Class Action Waiver.
      </Typography>
      <Typography>
        WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU AND ARKWOOD AGREE THAT
        EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
        CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
        OR REPRESENTATIVE PROCEEDING. Further, where permitted under the
        applicable law, unless both you and Arkwood agree otherwise, the court
        may not consolidate more than one person's claims with your claims, and
        may not otherwise preside over any form of a representative or class
        proceeding.
      </Typography>
      <Typography fontSize={["20px"]} fontWeight={["700"]}>
        7. Miscellaneous
      </Typography>
      <Typography>
        7.1. Governing Law. These Terms of Use shall be governed by and
        construed in accordance with the laws of the FDRE.
      </Typography>
      <Typography>
        7.2. Unsolicited Materials. Arkwood does not accept unsolicited
        materials or ideas for Arkwood’s content and is not responsible for the
        similarity of any of its content or programming in any media to
        materials or ideas transmitted to Arkwood.
      </Typography>
      <Typography>
        7.3. Customer Support. To find more information about our service and
        its features or if you need assistance with your account, please visit
        www.ethioarkwood.com. In certain instances, Customer Service may best be
        able to assist you by using a remote access support tool through which
        we have full access to your computer. If you do not want us to have this
        access, you should not consent to support through the remote access
        tool, and we will assist you through other means. In the event of any
        conflict between these Terms of Use and information provided by Customer
        Support or other portions of our websites, these Terms of Use will be
        controlled.
      </Typography>
      <Typography>
        7.4. Survival. If any provision or provisions of these Terms of Use
        shall be held to be invalid, illegal, or unenforceable, the validity,
        legality and enforceability of the remaining provisions shall remain in
        full force and effect.
      </Typography>
      <Typography>
        7.5. Changes to Terms of Use and Assignment. Arkwood may, from time to
        time, change these Terms of Use. We will notify you at least 30 days
        before such changes apply to you. We may assign or transfer our
        agreement with you including our associated rights and obligations at
        any time and you agree to cooperate with us in connection with such an
        assignment or transfer.
      </Typography>
      <Typography>
        7.6. Electronic Communications. We will send you information relating to
        your account (e.g. payment authorizations, invoices, changes in password
        or Payment Method, confirmation messages, notices) in electronic form
        only, for example via SMS gateways to your phone number provided or via
        emails to your email address provided.
      </Typography>
    </MainContainer>
  );
};

export default AgreementTerm;
