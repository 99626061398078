import { useSelector } from "react-redux";
import { theme } from "../../../styles/theme";
import { Flex } from "../../components/Blocks";
import Typography from "../../components/Blocks/Typography";
import SubscriptionCard from "../../components/Common/SubscriptionCard";
import Layout from "../../components/Layout";

const SubscriptionPage = () => {
  const packages = useSelector((state) => state.subscription.data);

  return (
    <Layout>
      <Flex
        alignItems={["center"]}
        mx="auto"
        py={32}
        width="90%"
        flexDirection={"column"}
        minHeight={["80vh"]}
      >
        <Typography
          fontWeight={["800"]}
          fontSize={["72px"]}
          mt={["10rem"]}
          textAlign={"center"}
          lineHeight={["auto", "auto", "auto", 0]}
          color={theme.colors.white[1]}
        >
          Discover your best price
        </Typography>
        <Typography
          fontWeight={["800"]}
          textAlign={"center"}
          fontSize={["32px"]}
          lineHeight={["auto", "auto", "auto", 0]}
          color={theme.colors.white[1]}
        >
          Offers tailored to your need
        </Typography>
        <Flex
          flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
          mt={[4]}
          flexDirection={["row"]}
          width={"100%"}
        >
          {packages?.results.map((item, index) => (
            <SubscriptionCard key={index} detail={item} />
          ))}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default SubscriptionPage;
