import { call, put, takeLatest } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import { getUserStart, setUserData, getUserError } from ".";
import apiRoutes from "../../app/API/routes";

function* workUserSaga(action) {
  try {
    // Make an API call to register the user
    const response = yield call(makeAPICall, {
      route: apiRoutes.user.me,
      method: "GET",
    });
    yield put(setUserData(response));
  } catch (error) {
    yield put(getUserError(error.message));
  }
}

function* userWatcherSaga() {
  yield takeLatest(getUserStart.type, workUserSaga);
}

export default userWatcherSaga;
