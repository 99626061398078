import React, { useContext, useEffect, useState } from "react";
import Login from "../pages/Login";
import { getUserFromLocalStorage } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import Typography from "../components/Blocks/Typography";
import { useSelector } from "react-redux";
import Onboarding from "../pages/Onboarding";
import Homepage from "../pages/Homepage";

const withAuth = (WrappedComponent) => {
  const ComponentWithAuthentication = (props) => {
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.userData);
    console.log(user);

    if (user?.access) {
      return <Homepage />;
    } else {
      navigate("/login");
      return <Login />;
    }

    // if (!user?.access) {
    //   navigate("/login");
    //   return <Login />;
    // } else if (user?.access) {

    // } else {
    //   navigate("/onboarding");
    //   return <Onboarding />;
    // }
  };

  return ComponentWithAuthentication;
};

export default withAuth;
