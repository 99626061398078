import styled from "styled-components";
import Typography from "../../Blocks/Typography";
import { Box, Button, Flex } from "../../Blocks";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../../styles/theme";
import ReactPlayer from "react-player";
import Modal from "../../Blocks/Modal";
import React from "react";
import { CloseIcon } from "../../../../assets/assets";
import { useSelector } from "react-redux";
import MovieCard from "../../Common/MovieCard";

const StyledImage = styled.img`
  filter: grayscale(100%);
  border-radius: 50%;
  border: 1px solid ${theme.colors.white[0]};
  width: 180px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${theme.colors.accent[1]};
  }
`;

const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const ArtistCard = ({ cast }) => {
  const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

  const movies = useSelector((state) => state.movies.data);

  return (
    <Flex
      alignItems={["center"]}
      onClick={() => setOpenRegisterModal(true)}
      flexDirection={["column"]}
      mx={3}
    >
      <Box>
        <StyledImage height="160" width="140" src={cast?.profile_image} />
      </Box>
      <Typography lineHeight={"0"} color={"#fff"}>
        {cast?.full_name}
      </Typography>
      <Modal
        onClose={() => setOpenRegisterModal(false)}
        open={openRegisterModal}
      >
        <CloseBtn onClick={() => setOpenRegisterModal(false)}>
          <CloseIcon width={"20px"} height={"20px"} />
        </CloseBtn>
        <Box>
          <ReactPlayer
            width={"100%"}
            url="https://www.youtube.com/watch?v=X8zLJlU_-60"
          />
        </Box>

        <Flex backgroundColor={theme.colors.secondary[3]}>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            width={["100%"]}
            mx={[4]}
            py={2}
          >
            <Flex alignItems={"center"}>
              <Box
                borderRadius={"48px"}
                height={"48px"}
                width={"48px"}
                mx={[2]}
              >
                <img
                  src={cast?.profile_image}
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <Typography
                fontFamily={"Protest Revolution, sans-serif"}
                color={theme.colors.white[0]}
                fontSize={["20px"]}
              >
                {cast?.full_name}
              </Typography>
            </Flex>
            <Flex
              py={2}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={["column"]}
            >
              <Typography
                lineHeight={0}
                color={theme.colors.white[0]}
                fontSize={["12px"]}
              >
                {cast?.place_of_birth}
              </Typography>
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent={["center"]} flexWrap={["wrap"]}>
          {movies?.results?.map((movie, index) => (
            <MovieCard key={index} movie={movie} />
          ))}
          {movies?.results?.map((movie, index) => (
            <MovieCard key={index} movie={movie} />
          ))}
          {movies?.results?.map((movie, index) => (
            <MovieCard key={index} movie={movie} />
          ))}
        </Flex>
      </Modal>
    </Flex>
  );
};

export default ArtistCard;
