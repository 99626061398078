import { useDispatch } from "react-redux";
import HomepageComponent from "../../components/Homepage";
import { useEffect } from "react";
import { getMoviesStart } from "../../../Slice/Movies";

function Homepage() {
  return <HomepageComponent />;
}

export default Homepage;
