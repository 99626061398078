import { call, put, takeLatest } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import { getMoviesStart, setMoviesData, getMoviesError } from ".";
import apiRoutes from "../../app/API/routes";

function* workMoviesSaga(action) {
  console.log(action.payload);
  try {
    const response = yield call(makeAPICall, {
      route: apiRoutes.movies.base,
      method: "GET",
      query: action.payload,
    });
    yield put(setMoviesData(response));
  } catch (error) {
    yield put(getMoviesError(error.message));
  }
}

function* moviesWatcherSaga() {
  yield takeLatest(getMoviesStart.type, workMoviesSaga);
}

export default moviesWatcherSaga;
