import styled from "styled-components";
import { AppStoreIcon, DesktopIcon, PlayStore } from "../../../assets/assets";
import { Flex } from "../../components/Blocks/Flex";
import TextInput from "../../components/Blocks/TextInput";
import Typography from "../../components/Blocks/Typography";
import { theme } from "../../../styles/theme";
import { SearchBar } from "../../components/Blocks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { login } from "../../../Slice/Auth";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/Arkwood_Logo.png";

const MainContainer = styled(Flex)`
  background: url(${require("../../../assets/images/bg_five.png")}) repeat,
    url(${require("../../../assets/images/bg_three.png")}) repeat;
  background-size: 50%;
`;

const LoginButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 2px;
  background-color: ${theme.colors.primary[0]};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${theme.fontSizes[5]};
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
`;

const StyledFlex = styled(Flex)`
  background: linear-gradient(to right, #16222a, #3a6073);
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 10;
`;

const Onboarding = () => {
  const user = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(login());
  }, [dispatch]);

  return (
    <MainContainer
      height={["100vh"]}
      alignItems={"center"}
      justifyContent={"center"}
      // backgroundColor={"#12161F"}
    >
      <GradientOverlay />
      <StyledFlex
        py={4}
        px={[2, 4, 6]}
        minHeight={["60%", "50%"]}
        alignItems={["center"]}
        justifyContent={["space-between"]}
        flexDirection={["column"]}
        width={["98%", "96%", "96%", "60%"]}
      >
        <Flex
          justifyContent={["flex-end"]}
          flexDirection={["column"]}
          mx="auto"
          width={["100%"]}
        >
          <Flex
            flexDirection={["column", "column", "row", "row"]}
            justifyContent={["flex-start"]}
            alignItems={["center"]}
          >
            <img src={Logo} width={100} />
            <Link
              to="https://ethioarkwood.com/"
              style={{ textDecoration: "none" }}
            >
              <Typography
                textAlign={["right"]}
                lineHeight={["0"]}
                fontSize={["40px"]}
                fontWeight={["800"]}
                color={theme.colors.primary[0]}
              >
                Arkwood
              </Typography>
            </Link>
          </Flex>
          <SearchBar isVisibleOnSmallScreen={true} filter={true} />
          <Flex flexDirection={["column"]} mt={2}>
            <Typography
              textAlign={["center"]}
              fontSize={["12px"]}
              color={theme.colors.white[0]}
            >
              Arkwood is top of African streaming website, where to watch movies
              online with small subscription fee. With a big database and great
              features, we're confident Arkwood is the best online website in
              the space that you can't simply miss!
            </Typography>
            <Typography
              textAlign={["center"]}
              fontSize={["12px"]}
              color={theme.colors.white[0]}
            >
              This site does not store any files on our server, we only linked
              to the media which is hosted on 3rd party services.
            </Typography>
          </Flex>
        </Flex>

        <Flex
          alignItems={["center"]}
          flexDirection={["row"]}
          justifyContent={["space-between"]}
          width={["100%"]}
          mt={[2]}
        >
          <Link
            to="/agreementTerms"
            style={{
              fontSize: "12px",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <Typography color={theme.colors.primary[0]}>
              terms of agreement
            </Typography>
          </Link>
          <Link
            to="/privacyPolicy"
            style={{
              fontSize: "12px",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <Typography color={theme.colors.primary[0]}>
              privacy policy
            </Typography>
          </Link>
        </Flex>

        <Flex mt={2} flexDirection={["column"]} width={["100%"]}>
          <LoginButton onClick={() => navigate("/login")} mt={[2]}>
            Login
          </LoginButton>
          <LoginButton
            onClick={() => navigate("/signup")}
            style={{ marginTop: "12px" }}
          >
            Sign up
          </LoginButton>
        </Flex>
        <Flex
          justifyContent={["space-between"]}
          mt={4}
          flexDirection={["row"]}
          width={["100%"]}
        >
          <LoginButton style={{ backgroundColor: "#191919", width: "48%" }}>
            <Typography>Appstore</Typography>
            <AppStoreIcon height={24} width={48} />
          </LoginButton>
          <LoginButton style={{ backgroundColor: "#191919", width: "48%" }}>
            <Typography>Playstore</Typography>
            <PlayStore height={20} width={48} />
          </LoginButton>
        </Flex>
      </StyledFlex>
    </MainContainer>
  );
};

export default Onboarding;
