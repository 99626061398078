import { call, put, takeLatest } from "redux-saga/effects";
import { makeAPICall } from "../../app/API/apiCall";
import {
  getTrendingMoviesStart,
  setTrendingMoviesData,
  getTrendingMoviesError,
} from ".";
import apiRoutes from "../../app/API/routes";

function* workTrendingMoviesSaga(action) {
  try {
    // Make an API call to register the user
    const response = yield call(makeAPICall, {
      route: apiRoutes.movies.base,
      method: "GET",
      query: { is_trainding: true },
    });
    console.log(response, "response");
    yield put(setTrendingMoviesData(response));
  } catch (error) {
    console.log(error);
    yield put(getTrendingMoviesError(error.message));
  }
}

function* trendingMoviesWatcherSaga() {
  yield takeLatest(getTrendingMoviesStart.type, workTrendingMoviesSaga);
}

export default trendingMoviesWatcherSaga;
