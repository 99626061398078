import styled from "styled-components";
import { Flex, Box } from "../../Blocks";
import { RecommendedIcon } from "../../../../assets/assets";
import MovieCard from "../../Common/MovieCard";
import { useSelector } from "react-redux";

const RecommendedContainer = styled(Flex)`
  margin: 20px auto;
  flex-direction: column;
  z-index: 999;
`;

const SectionTitle = styled.h1`
  color: #fff;
  line-height: 0px;
  font-size: 24px;
  margin: 24px 12px;
`;

const Recommended = () => {
  const movies = useSelector((state) => state.movies.data);

  return (
    <RecommendedContainer
      alignItems="flex-start"
      flexDirection="column"
      mx="auto"
      height="fit-content"
    >
      <Flex alignItems="center" mx={"5%"} my={2}>
        <RecommendedIcon height={"24px"} width={"24px"} />
        <SectionTitle>Recommended</SectionTitle>
      </Flex>
      <Box py={4} mx="auto" width={["100%"]}>
        <Flex justifyContent="flex-start" flexWrap="wrap" width="90%" mx="auto">
          {movies?.results?.map((movie) => (
            <MovieCard movie={movie} />
          ))}
        </Flex>
      </Box>
    </RecommendedContainer>
  );
};

export default Recommended;
