import styled from "styled-components";
import {
  compose,
  space,
  layout,
  border,
  typography,
  background,
} from "styled-system";

const TextInput = styled.input`
  outline: none;
  border-radius: 3px;
  height: 48px;
  outline: none;
  border: 0.5px solid #333;

  &:disabled {
    cursor: not-allowed;
  }
  ${compose(space, layout, border, typography, background)}
`;

export default TextInput;
