import styled from "styled-components";
import { Button, Flex } from "../../Blocks";
import {
  CalendarIcon,
  ClockIcon,
  CloseIcon,
  PlayIcon,
  StarIcon,
} from "../../../../assets/assets";
import { theme } from "../../../../styles/theme";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMoviePayment from "../../../hooks/useMoviePayment";
import ReactPlayer from "react-player";
import Typography from "../../Blocks/Typography";
import Modal from "../../Blocks/Modal";
import SwitchButton from "../../Common/SwitchButton";
import Chapa from "../../Checkout/Chapa";
import Stripe from "../../Checkout/Stripe";

const HeroContainer = styled(Flex)`
  position: relative;
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
`;

const MovieInfo = styled(Flex)`
  position: absolute;
  left: 0;
`;

const MovieTitle = styled.h1`
  color: ${theme.colors.white[0]}
  font-size: 64px;
  text-shadow:
  1px 1px 2px black,
  font-family: "Alfa Slab One", serif;
  font-weight:800;
  transform: scaleY(2.5);
  0 0 1em ${theme.colors.primary[0]},
  0 0 0.2em ${theme.colors.primary[0]};

`;

const MovieDescription = styled.p`
  color: ${theme.colors.white[0]};
  font-size: 16px;
  font-weight: 600;
  margin-top: -12px;
  z-index: 20;
  white-space: wrap;
`;

const MoreInfoButton = styled(Button)`
  background-color: transparent;
  font-weight: 800;
`;

const LoginButton = styled(Button)`
  background-color: transparent;
  gap: 12px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.primary[0]};
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.6) 20%,
    rgba(0, 0, 0, 0.7) 40%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 70%
  );
`;
const CloseBtn = styled(Flex)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 99;
  background-color: ${theme.colors.white[1]};
  padding: 8px;
  border-radius: 50%;
`;

const ProductionName = styled(Typography)`
  z-index: 10;
  background-color: ${theme.colors.primary[0]};
  color: ${theme.colors.white[0]};
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: 16px;
`;

const Pg = styled(Typography)`
  z-index: 10;
  background-color: ${theme.colors.white[0]};
  color: ${theme.colors.black[0]};
  width: fit-content;
  padding: 6px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 12px;
`;

const Ipg = styled(Typography)`
  z-index: 10;
  color: ${theme.colors.white[0]};
  width: fit-content;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 12px;
`;

const InfoSec = styled(Flex)`
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
`;

const IconAndData = styled(Flex)`
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const InfoList = styled(Flex)`
  gap: 1rem;
  align-items: center;
`;

const MainBtns = styled(Flex)`
  gap: 1rem;
`;

const HeroSingle = ({ movie }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentMethodVisible, setIsPaymentMethodVisible] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState("Chapa");

  const isPaid = useMoviePayment(movie?.id);

  const paymentMethods = ["Chapa", "Stripe"];

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleWatchMovie = async (id) => {
    // const isPaid = await useMoviePayment(id);

    if (isPaid) {
      navigate(`/movie/${id}`);
    } else {
      setIsPaymentMethodVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleLanguageToggle = (input) => {
    setActivePaymentMethod(input);
  };

  const renderPaymentMethod = () => {
    if (activePaymentMethod === "Stripe") {
      return <Stripe type={"movie"} id={movie?.id} />;
    } else {
      return <Chapa type={"movie"} id={movie?.id} />;
    }
  };

  return (
    <>
      <HeroContainer minWidth={["100vw"]}>
        <HeroImage src={movie?.poster?.high_quality} />
        <MovieInfo
          height="100%"
          justifyContent="center"
          px={"5%"}
          flexDirection="column"
          width={["100%", "100%", "70%", "50%"]}
        >
          <ProductionName>Nolawi</ProductionName>
          <MovieTitle
            style={{
              color: theme.colors.white[0],
              fontSize: "40px",
              zIndex: 10,
            }}
          >
            {movie?.name}
          </MovieTitle>
          <Flex
            my={[4]}
            mx={[2]}
            style={{
              zIndex: 10,
            }}
          >
            {Array.from({ length: movie["movies rating"] }, (_, index) => (
              <StarIcon style={{ marginRight: "10px" }} />
            ))}
          </Flex>
          <Flex mx={[2]} zIndex={[20]} width={["90%", "50%"]}>
            <MovieDescription>{movie?.description}</MovieDescription>
          </Flex>
          <InfoSec
            mx={[2]}
            style={{
              zIndex: 10,
            }}
          >
            <Pg>{movie?.adult_content ? PG13 : "Family friendly"}</Pg>
            <IconAndData>
              <CalendarIcon height={"24px"} />
              <Typography
                fontSize={["12px"]}
                fontWeight={["600"]}
                color={theme.colors.white[0]}
              >
                {new Date(movie?.created_at).getFullYear()}
              </Typography>
            </IconAndData>
          </InfoSec>

          <Flex
            zIndex={[20]}
            flexDirection={["column-reverse", "row", "row"]}
            mt={12}
          >
            <LoginButton
              onClick={() => handleCardClick(movie?.id)}
              style={{
                marginRight: "12px",
                marginBottom: "16px",
                cursor: "pointer",
              }}
            >
              <PlayIcon height="16px" width="16px" />
              <Typography fontWeight={["800"]}> Watch Now</Typography>
            </LoginButton>
            <MoreInfoButton>More Info</MoreInfoButton>
          </Flex>
        </MovieInfo>

        <GradientOverlay />
      </HeroContainer>
      <Modal onClose={handleClose} open={isModalOpen}>
        {!isPaymentMethodVisible ? (
          <>
            <CloseBtn onClick={handleClose}>
              <CloseIcon height={"24px"} width={"24px"} />
            </CloseBtn>
            <ReactPlayer width={"100%"} url={movie?.trailer} />
            <MainBtns px={[3]} py={[3]}>
              <Button
                onClick={() => handleWatchMovie(movie?.id)}
                width={"100%"}
                style={{ marginTop: "1rem" }}
              >
                Watch Movie
              </Button>
              <Button
                width={"100%"}
                style={{
                  marginTop: "1rem",
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.colors.white[0]}`,
                }}
                onClick={handleClose}
              >
                Change Movie
              </Button>
            </MainBtns>
            <Flex backgroundColor={theme.colors.secondary[3]}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={["100%"]}
                mx={[4]}
              >
                <Typography
                  fontFamily={"Protest Revolution, sans-serif"}
                  color={theme.colors.white[0]}
                  fontSize={["32px"]}
                >
                  {movie?.title}
                </Typography>

                <Flex
                  py={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexDirection={["column"]}
                >
                  <Typography
                    lineHeight={0}
                    color={theme.colors.white[0]}
                    fontSize={["12px"]}
                  >
                    {movie?.made_in}
                  </Typography>
                  <Typography
                    lineHeight={0}
                    color={theme.colors.white[0]}
                    fontSize={["12px"]}
                  >
                    {movie?.genre?.map((genre) => (
                      <em key={genre.id} style={{ marginRight: "10px" }}>
                        {genre?.name}
                      </em>
                    ))}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>

            <Flex mx={[4]} flexDirection={"column"} py={[4]}>
              <Flex>
                <StarIcon style={{ marginRight: "10px" }} />
                <StarIcon style={{ marginRight: "10px" }} />
                <StarIcon style={{ marginRight: "10px" }} />
              </Flex>

              <Typography color={theme.colors.white[0]} fontSize={["16px"]}>
                {movie?.description}
              </Typography>
            </Flex>
          </>
        ) : (
          <Flex
            px={0}
            alignItems={["center"]}
            justifyContent={["space-between"]}
            flexDirection={["column"]}
            width={["100%"]}
          >
            <CloseBtn onClick={handleClose}>
              <CloseIcon height={"24px"} width={"24px"} />
            </CloseBtn>
            <Flex
              my={4}
              px={[0]}
              alignItems={["center"]}
              justifyContent={["space-between"]}
              borderRadius={[12]}
              height={[64]}
              backgroundColor={[theme.colors.black[2]]}
              flexDirection={["row"]}
            >
              {paymentMethods?.map((item, index) => (
                <SwitchButton
                  options={paymentMethods}
                  value={item}
                  active={activePaymentMethod}
                  handler={handleLanguageToggle}
                />
              ))}
            </Flex>
            <Flex backgroundColor={theme.colors.white[0]} width={"100%"}>
              {renderPaymentMethod()}
            </Flex>
          </Flex>
        )}
      </Modal>
    </>
  );
};

export default HeroSingle;
