import { useState, useEffect } from "react";
import axios from "axios";
import apiRoutes from "../API/routes";
import { getUserFromLocalStorage } from "../../utils/functions";

const API_ROUTE = process.env.REACT_APP_BASE_URL;

const useMoviePayment = (movieId) => {
  const [isPaid, setIsPaid] = useState(false);
  const user = getUserFromLocalStorage();
  const token = user?.access;

  const url = `${API_ROUTE}/${apiRoutes.movies.base}/${movieId}`;

  useEffect(() => {
    const checkMoviePayment = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });

        if (response.status === 200) {
          setIsPaid(true);
        } else {
          setIsPaid(false);
        }
      } catch (error) {
        console.error("Error checking movie payment:", error);
        setIsPaid(false);
      }
    };

    checkMoviePayment();
  }, [movieId]);

  return isPaid;
};

export default useMoviePayment;
