import { useEffect, useState } from "react";
import { Box } from "../Blocks";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { stripePromise } from "../../../utils/constants";
import { getUserFromLocalStorage } from "../../../utils/functions";
import apiRoutes from "../../API/routes";

const API_ROUTE = process.env.REACT_APP_BASE_URL;

const Stripe = ({ type, id }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const user = getUserFromLocalStorage();

  let url;

  if (type === "movie") {
    url = `${API_ROUTE}/${apiRoutes.subscriptions.stripeMovie}/${id}/`;
  } else {
    url = `${API_ROUTE}/${apiRoutes.subscriptions.stripePackage}/${id}/`;
  }

  const token = user?.access;

  useEffect(() => {
    fetch(url, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.client_secret);
      });
  }, [url, id]);

  return (
    <Box minHeight={["100vh"]} width={["100%"]} py={[4]}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
          style={{ backgroundColor: "red" }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Box>
  );
};

export default Stripe;
